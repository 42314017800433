import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ImageCarousel.css';
import { Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import Icon from '../Icon';
import Image from '../Image/Image';
import ImageMagnifier from './ImageMagnifier';
import constants from 'src/utils/constants';
import { useState } from 'react';
import { transform_image_url } from 'src/utils/ImageConstants';
import { makeStyles } from '@mui/styles';
import ImageLightbox from '../LightBox/LightBox';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
	icon_styles: {
		position: 'absolute',
		top: '8px',
		right: '8px',
		display: 'flex',
		flexDirection: 'column',
		gap: '8px',
		zIndex: 1,
	},
}));

export interface ImageProps {
	id?: string;
	src?: string | undefined;
	fallback?: string;
}
export interface CarouselProps {
	images?: any[];
	width?: string | number;
	style?: any;
	imageHeight?: string | number;
	imageWidth?: string | number;
	magnifierBoxDimension?: number;
}

const customRenderThumb = (children: any) =>
	children?.map((item: any) => {
		const imageId = item?.props?.id;
		const imageSrc = item?.props?.src;
		const fall_back_image_src = item?.props?.children?.[0]?.props?.src;
		const fallbackImageId = item?.props?.fallback;

		return (
			<Image
				key={imageId}
				src={transform_image_url(imageSrc || fall_back_image_src, 'PDP_THUMBNAIL')}
				fallbackSrc={fallbackImageId}
				alt={fallbackImageId}
				imgClass='border_radius thumbs_border object_fit'
				width='60px'
				height='60px'
			/>
		);
	});

const ImageCarousel = ({ images, width, magnifierBoxDimension, imageWidth, imageHeight, style }: CarouselProps) => {
	const classes = useStyles();
	const [open, set_open] = useState(false);
	const [selected_image_index, set_selected_image_index] = useState(0);
	const image_preview = useSelector((state: any) => state?.settings?.image_preview);

	const handle_show_light_box = (index: number) => {
		if (image_preview) {
			set_selected_image_index(index);
			set_open(true);
		}
	};

	return (
		<>
			<Carousel
				showIndicators={false}
				centerMode
				centerSlidePercentage={100}
				onClickItem={(index: any) => {
					handle_show_light_box(index);
				}}
				renderArrowPrev={({ onClickHandler, hasPrev }: any) =>
					hasPrev && (
						<Grid
							container
							justifyContent='center'
							alignItems='center'
							onClick={onClickHandler}
							className='arrow'
							style={{ left: 15, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)' }}>
							<Icon iconName='IconChevronLeft' />
						</Grid>
					)
				}
				renderArrowNext={({ onClickHandler, hasNext }: any) =>
					hasNext && (
						<Grid
							container
							justifyContent='center'
							alignItems='center'
							onClick={onClickHandler}
							className='arrow'
							style={{ right: 15, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)' }}>
							<Icon iconName='IconChevronRight' />
						</Grid>
					)
				}
				showThumbs
				renderThumbs={customRenderThumb}
				width={width}
				className='custom-carousel'>
				{images?.map((image: any, index: number) => (
					<Grid key={image?.id} style={{ position: 'relative', cursor: image_preview ? 'pointer' : 'default' }}>
						<ImageMagnifier
							src={image?.src}
							onClick={() => {
								handle_show_light_box(index);
							}}
							style={style}
							fallback={image?.fallback || constants.FALLBACK_IMAGE}
							ImageWidth={imageWidth}
							ImageHeight={imageHeight}
							magnifierWidth={magnifierBoxDimension}
							magnifierHeight={magnifierBoxDimension}
							zoomLevel={4}
						/>
						{image_preview && (
							<div className={classes.icon_styles}>
								<Icon
									iconName='IconArrowsDiagonal'
									onClick={() => {
										handle_show_light_box(index);
									}}
								/>
							</div>
						)}
					</Grid>
				))}
			</Carousel>
			{image_preview && (
				<ImageLightbox
					open={open}
					onClose={() => set_open(false)}
					images={images}
					selectedIndex={selected_image_index}
					overrideWidth={900}
					overrideHeight={700}
				/>
			)}
		</>
	);
};

ImageCarousel.defaultProps = {
	images: [
		{
			id: '1',
			src: '',
			fallback: '',
		},
	],
	width: '75%',
	magnifierBoxDimension: 400,
	imageHeight: '100%',
	imageWidth: '100%',
};

export default ImageCarousel;
