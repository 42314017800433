import { Typography, Image, Grid, Button } from 'src/common/@the-source/atoms';
import ImageLinks from 'src/assets/images/ImageLinks';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';

const useStyles = makeStyles(() => ({
	container: {
		height: 'calc(100vh - 80px)',
		overflow: 'hidden',
		background: 'white',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
}));

const CustomError = ({ set_show_customise }: any) => {
	const styles = useStyles();

	return (
		<Grid container className={styles.container}>
			<Image src={ImageLinks.ErrorLogo} width={325} height={252} />
			<Typography variant='h5' sx={{ fontSize: '20px' }}>
				{t('ErrorScreens.CustomError.UnavailableProduct')}
			</Typography>
			<Typography variant='body2' sx={{ fontSize: '16px', width: '400px', textAlign: 'center', color: '#00000099' }}>
				{t('ErrorScreens.CustomError.ErrorMessage')}
			</Typography>
			<Button onClick={() => set_show_customise(false)} sx={{ marginTop: '20px' }}>
				{t('ErrorScreens.CustomError.GoBack')}
			</Button>
		</Grid>
	);
};

export default CustomError;
