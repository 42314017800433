import { RadioGroup as MuiRadioGroup, FormControl } from '@mui/material';
import { IPriceList } from 'src/reducers/buyer';
import { Chip, Grid, Image, Radio } from '../../atoms';
import { useEffect, useState } from 'react';
import CustomText from '../../CustomText';
import _ from 'lodash';
import React from 'react';
import FormBuilder from '../FormBuilder/FormBuilder';
import { get_formatted_price_with_currency } from 'src/utils/common';
import { useSelector } from 'react-redux';

// type RadioBaseProps = Pick<MuiRadioProps, 'size' | 'checked' | 'disabled' | 'onChange'>;

export interface RadioButtonProps {
	selectedOption: string;
	options: IPriceList[];
	onChange: (name: string) => any;
	disabled?: boolean;
	label_style?: any;
	radio_label_style?: any;
	children?: any;
	child_style?: any;
}

const RadioGroup = ({
	selectedOption: _selectedOption,
	options,
	onChange,
	label_style = {},
	disabled = false,
	radio_label_style = {},
	children,
	child_style,
}: RadioButtonProps) => {
	const [selectedOption, setSelectedOption] = useState(_selectedOption);
	const [has_assigned, set_has_assigned] = useState(false);
	const currency = useSelector((state: any) => state?.settings?.currency);

	const handleChange = (event: any) => {
		setSelectedOption(event.target.value);
		onChange(event.target.value);
	};

	const render_chips = (custom: any) => {
		if (custom?.is_authorized)
			return (
				<Chip
					sx={{ fontWeight: '700' }}
					bgColor='#F9DFAC'
					label={`${get_formatted_price_with_currency(currency, custom?.authorized_amount)} authorized`}
					textColor='#684500'
				/>
			);
		else if (has_assigned && custom.is_selected)
			return <Chip sx={{ fontWeight: '700' }} bgColor='#F0AF30' label='Assigned' textColor='#FFF' />;
		else if (!has_assigned && custom.is_default)
			return <Chip sx={{ fontWeight: '700' }} bgColor='#F0AF30' label='Default' textColor='#FFF' />;
	};

	const render_custom_label = (custom: any) => {
		return (
			<Grid container alignItems='center' width='100%' justifyContent={'space-between'} pt='1rem'>
				<Grid>
					<Grid display='flex' alignItems='center'>
						<CustomText type='Title' children={custom.title} />
						{!custom?.is_authorized && <Image style={{ marginLeft: '1rem' }} src={custom.logo} width='40' />}
					</Grid>
					<Grid display='flex' alignItems='center'>
						<CustomText color='rgba(0, 0, 0, 0.60)' children={custom.sub_title} />
						{custom?.is_authorized && <Image src={custom.logo} width='40' />}
					</Grid>
				</Grid>

				<Grid>{render_chips(custom)}</Grid>
			</Grid>
		);
	};
	const render_label = (option: any) => {
		return (
			<Grid>
				<CustomText style={{ ...radio_label_style }} type='Title'>
					{option?.label}
				</CustomText>
				<CustomText type='Body'>{option?.custom_labels}</CustomText>
			</Grid>
		);
	};

	useEffect(() => {
		const temp = _.filter(options, (option) => option?.custom_labels?.is_selected);
		if (temp?.length > 0) {
			set_has_assigned(true);
		}
	}, [options]);

	return (
		<FormControl fullWidth sx={{ minWidth: 150 }}>
			<MuiRadioGroup
				aria-labelledby='demo-controlled-radio-buttons-group'
				name='controlled-radio-buttons-group'
				value={selectedOption}
				sx={label_style}
				defaultValue={selectedOption}
				onChange={handleChange}>
				{options?.map((option: any) => (
					<>
						<Grid key={option?.value} sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
							<Radio
								size='small'
								checked={option.value === (selectedOption || _selectedOption) && !disabled}
								value={option?.value}
								disabled={disabled}
								onChange={handleChange}
							/>
							{!option.is_custom ? render_label(option) : render_custom_label(option.custom_labels)}
						</Grid>
						<Grid sx={child_style}>
							{children && option.value === (selectedOption || _selectedOption) && (
								<Grid bgcolor={'#F7F8F8'} padding={2} borderRadius={1.6}>
									{_.map(children, (attribute) => (
										<React.Fragment key={attribute?.id}>
											<FormBuilder
												placeholder={attribute?.name}
												label={attribute?.name}
												name={`${attribute?.id}`}
												validations={{
													required: Boolean(attribute?.required),
												}}
												style={{ backgroundColor: 'white' }}
												defaultValue={attribute?.value}
												type={attribute?.type}
												disabled={attribute?.disabled}
											/>
										</React.Fragment>
									))}
								</Grid>
							)}
						</Grid>
					</>
				))}
			</MuiRadioGroup>
		</FormControl>
	);
};

export default RadioGroup;
