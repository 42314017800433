import utils from '../utils';

export interface SSRMFilterModel {
	values?: string[];
	filterType: string;
	customType?: string;
	filter: string | number;
	filterTo: string | number | null;
	type: string;
}

export interface SSRMSortModel {
	colId: string;
	sort: string;
}

export interface SSRMSortModelDict {
	[key: string]: SSRMSortModel;
}

export interface SSRMFilterModelDict {
	[key: string]: SSRMFilterModel;
}

export interface SSRMInput {
	startRow: number;
	endRow: number;
	sortModel: SSRMSortModel[];
	filterModel: SSRMFilterModelDict;
}

const order_listing = {
	get_document_config: (params?: any, signal?: any) => {
		let new_url = 'document/v2/ssrm/config';
		if (params) {
			new_url = `${new_url}${params}`;
		}
		return utils.request({
			url: new_url,
			method: 'GET',
			response_array: true,
			mock: false,
			signal,
		});
	},
	get_document_list: (data: SSRMInput, signal?: any) => {
		return utils.request({
			url: 'document/v2/ssrm/search',
			method: 'POST',
			data,
			response_array: true,
			mock: false,
			signal,
		});
	},
	get_invoice: (data: any) => {
		return utils.request({
			url: 'invoice/v1/pdf',
			method: 'POST',
			data,
		});
	},
};

export default order_listing;
