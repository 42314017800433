import { Paper as MuiPaper, PaperProps as MuiPaperProps } from '@mui/material';

export interface PaperProps extends MuiPaperProps {
	borderRadius?: 'string';
	minHeight?: 'string';
	background?: 'string';
	padding?: 'string';
	boxShadow?: string;
}

const Paper = ({ children, borderRadius, minHeight, background, padding, boxShadow, sx, ...rest }: PaperProps) => (
	<MuiPaper
		{...rest}
		sx={{
			...sx,
			backgroundColor: background,
			minHeight,
			borderRadius,
			padding,
			boxShadow,
		}}>
		{children}
	</MuiPaper>
);

Paper.defaultProps = {
	background: '#F7F8FA',
	borderRadius: '20px',
	boxShadow: 'none',
};

export default Paper;
