import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from '@mui/material';

type SkeletonBaseProps = Pick<MuiSkeletonProps, 'variant' | 'width' | 'height' | 'animation' | 'sx'>;

export interface SkeletonProps extends SkeletonBaseProps {}

const Skeleton = ({ variant, height, width, animation, sx }: SkeletonProps) => (
	<MuiSkeleton variant={variant} height={height} width={width} animation={animation} sx={sx} />
);

Skeleton.defaultProps = {
	color: 'A9A9A9',
};
export default Skeleton;
