export const response = {
	message: 'Ok',
	status_code: 200,
	success: true,
	data: [
		{
			label: 'Products',
			value: 'products',
			sub_entities: [],
			image: 'https://sourcerer.tech/assets/65475616-8765-47b3-bdb0-cae3e86eb45c',
			selected_image: 'https://sourcerer.tech/assets/dcaf9049-2991-4f1e-933a-03cba84c0ea8',
			disabled: false,
		},
		{
			label: 'Categories',
			value: 'categories',
			sub_entities: [],
			image: 'https://sourcerer.tech/assets/4ab37f5a-faa7-49b2-8d94-23e1aa00bcac',
			selected_image: 'https://sourcerer.tech/assets/146456af-a02e-4d06-b822-d8b131151601',
			disabled: false,
		},
		{
			label: 'Collections',
			value: 'collections',
			sub_entities: [],
			image: 'https://sourcerer.tech/assets/1bba4f92-34df-4de2-86b1-78343a5bdcf2',
			selected_image: 'https://sourcerer.tech/assets/b24fcb8d-a953-42ee-897f-10561d0c073a',
			disabled: false,
		},
		{
			label: 'Inventory',
			value: 'inventory',
			sub_entities: [],
			image: 'https://sourcerer.tech/assets/afb060b5-e811-451c-adc5-a9603791b117',
			selected_image: 'https://sourcerer.tech/assets/2f30599e-bba9-48a6-bc77-44a47aafccbf',
			disabled: true,
		},
		{
			label: 'Pricing',
			value: 'pricing',
			sub_entities: [],
			image: 'https://sourcerer.tech/assets/9b160f3a-df0b-4a9e-bb03-201c78ea03ae',
			selected_image: 'https://sourcerer.tech/assets/0f531de0-5ef0-43db-bd89-5ea693d38747',
			disabled: true,
		},
		{
			label: 'Buyers',
			value: 'buyers',
			sub_entities: [
				{
					label: 'Buyers',
					value: 'basic_information',
					image: '',
					selected_image: '',
					disabled: false,
				},
				{
					label: 'Contacts',
					value: 'contacts',
					image: '',
					selected_image: '',
					disabled: false,
				},
				{
					label: 'Addresses',
					value: 'addresses',
					image: '',
					selected_image: '',
					disabled: false,
				},
			],
			image: 'https://sourcerer.tech/assets/25be8555-2cfb-4feb-aff7-fd80bcbfef35',
			selected_image: 'https://sourcerer.tech/assets/0dac5a60-866c-47d2-afb7-96ae9d3c15e2',
			disabled: false,
		},
		{
			label: 'Orders & Quotes',
			value: 'documents',
			sub_entities: [
				{
					label: 'Orders & Quotes',
					value: 'order_quote',
					image: '',
					selected_image: '',
					disabled: false,
				},
				{
					label: 'Orders & Quotes items',
					value: 'order_quote_items',
					image: '',
					selected_image: '',
					disabled: false,
				},
			],
			image: 'https://sourcerer.tech/assets/4e984c30-8f91-4ef5-997f-6687942940ec',
			selected_image: 'https://sourcerer.tech/assets/c24eb743-8f47-444f-84f1-857dbe806787',
			disabled: false,
		},
	],
	paginator: {},
};
