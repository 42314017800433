import { makeStyles } from '@mui/styles';
import CustomText from '../../CustomText';
import { Grid, Icon } from '../../atoms';
import { t } from 'i18next';

interface IHasSimilarProps {
	similarDrawer: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

const useStyles = makeStyles(() => ({
	container: {
		display: 'inline-flex',
		alignItems: 'center',
		gap: '2px',
		zIndex: 2,
		right: 8,
		bottom: 4,
		position: 'absolute',
		background: '#F7F8FA',
		border: '1px solid #D1D6DD',
		borderRadius: '32px',
		padding: '3px 4px',
		cursor: 'pointer',
		color: ' #4F555E',
		boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.12)',
	},
	text: {
		lineHeight: 'normal',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		color: ' #4F555E',
	},
}));

const HasSimilar: React.FC<IHasSimilarProps> = ({ similarDrawer }) => {
	const classes = useStyles();
	return (
		<Grid onClick={similarDrawer} className={classes.container}>
			<Icon
				iconName='IconCards'
				sx={{
					fontSize: '11px',
					color: ' #4F555E',
					width: '14px',
					height: '14px',
				}}
			/>
			<CustomText type='CaptionBold' className={classes.text}>
				{t('Product.Similar')}
			</CustomText>
		</Grid>
	);
};

export default HasSimilar;
