import React from 'react';
import styles from '../Cell.module.css';
import { Chip } from 'src/common/@the-source/atoms';
import utils from 'src/utils/utils';

interface Props {
	value: string[];
	valueFormatted?: any;
}

const TagsCellRenderer: React.FC<Props> = ({ value, ...rest }) => {
	const { valueFormatted } = rest;
	const { textColor, bgColor } = utils.get_chip_color_by_tag(String(valueFormatted?.toLowerCase()));

	const chip_style = {
		marginRight: 1,
		fontSize: 12,
		border: '2px solid white',
		textTransform: 'capitalize',
	};

	return (
		<div className={styles.agGridCustomCell}>
			<Chip textColor={textColor} bgColor={bgColor} label={valueFormatted} sx={{ ...chip_style }} />
		</div>
	);
};

export default TagsCellRenderer;
