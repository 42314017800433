import { Grid, IconButton } from '@mui/material';

import Button from '../../atoms/Button/Button';
import { IconNames } from '../../atoms/Icon/baseTypes';
import Icon from '../../atoms/Icon/Icon';
import SnackBar, { SnackbarProps } from '../../atoms/Snackbar/Snackbar';
import Typography from '../../atoms/Typography/Typography';
import _ from 'lodash';

export interface ToasterProps extends SnackbarProps {
	message?: React.ReactNode;
	action?: React.ReactNode;
	autoHideDuration?: number;
	state: 'error' | 'warning' | 'success';
	title?: any;
	subtitle?: any;
	subtitle_font_variant?: string;
	iconColor?: string;
	handleSecondry?: any;
	handlePrimary?: any;
	showActions: boolean;
	containerStyle?: any;
	showCross: boolean;
	primaryBtnName?: string;
	secondryBtnName?: string;

	iconSize?: 'small' | 'medium' | 'large';

	anchorOrigin?: {
		vertical: 'top' | 'bottom';
		horizontal: 'left' | 'center' | 'right';
	};
	onClose?: () => void;
	open?: boolean;
}

const Toaster = ({
	message,
	action,
	autoHideDuration = 3000,
	anchorOrigin = { vertical: 'top', horizontal: 'right' },
	onClose = () => {},
	open = false,
	state,
	iconColor,
	iconSize = 'large',
	title,
	subtitle,
	subtitle_font_variant = 'subtitle2',
	handleSecondry,
	primaryBtnName,
	showCross,
	secondryBtnName,
	handlePrimary,
	containerStyle,
	showActions = false,
	...rest
}: ToasterProps) => {
	const bodyResolver = () => {
		let color = '#8CB910';
		let name: IconNames = 'IconCircleCheckFilled';
		if (state === 'error') {
			name = 'IconAlertCircleFilled';
			color = '#D74C10';
		} else if (state === 'warning') {
			color = '#F0AF30';
			name = 'IconAlertTriangleFilled';
		}

		const currentIconName: IconNames = name;
		const colorName = color;
		const comp = (
			<Grid sx={{ padding: '1.6rem' }}>
				<Typography variant='h4' align='center'>
					<Icon size={iconSize} color={colorName} sx={{ fontSize: '36px' }} iconName={currentIconName} />
				</Typography>
				<Typography align='center' color='white' variant='h5'>
					{title}
				</Typography>
				<Grid sx={{ marginTop: '10px' }}>
					<Typography align='center' color='rgba(255, 255, 255, 0.8)' variant={subtitle_font_variant}>
						{_.capitalize(subtitle)}
					</Typography>
				</Grid>
				{showActions && (
					<Grid
						sx={{
							width: '85%',
							display: 'flex',
							margin: '1.8rem auto 0',
							justifyContent: 'space-between',
							...containerStyle,
						}}>
						{handleSecondry && (
							<Button
								onClick={handleSecondry}
								sx={{
									background: 'white',
									':hover': {
										backgroundColor: 'white',
										opacity: '0.9',
									},
								}}
								variant='outlined'>
								{secondryBtnName}
							</Button>
						)}
						{primaryBtnName && <Button onClick={handlePrimary}>{primaryBtnName}</Button>}
					</Grid>
				)}
			</Grid>
		);
		return comp;
	};
	return (
		<SnackBar
			open={open}
			message={bodyResolver()}
			action={
				<IconButton
					sx={{
						position: 'absolute',
						top: '5px',
						right: '5px',
						':hover': {
							background: 'transparent',
						},
					}}>
					{showCross ? <Icon onClick={onClose} color='white' iconName='IconX' /> : <> </>}
				</IconButton>
			}
			sx={{
				background: '#2D2D2D !important',
				borderRadius: '12px',
				width: '324px',
				zIndex: 99999,
			}}
			autoHideDuration={autoHideDuration}
			onClose={onClose}
			anchorOrigin={anchorOrigin}
			{...rest}
		/>
	);
};

Toaster.defaultProps = {
	message: 'Message',
	action: <Icon color='white' iconName='IconX' />,
	autoHideDuration: 3000,
	onClose: () => {},
	open: true,
	anchorOrigin: {
		vertical: 'top',
		horizontal: 'right',
	},
	title: 'title',
	subtitle: 'subtitle',
	primaryBtnName: 'primary',
	secondryBtnName: 'secondry',
	handleSecondry: () => {},
	handlePrimary: () => {},
	iconColor: '#8CB910',
	iconSize: 'small',
};

export default Toaster;
