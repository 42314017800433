import _ from 'lodash';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Icon } from 'src/common/@the-source/atoms';

interface Props {
	handle_upload: (file: any) => void;
	set_show_toast?: any;
	title?: string;
	sub_title?: string;
}

function DropZone({ handle_upload, set_show_toast, sub_title = 'Ex: Business Cards, Brochures etc', title = 'Add attachments' }: Props) {
	const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
		handle_upload(acceptedFiles);

		if (!_.isEmpty(rejectedFiles)) {
			const file_name = _.get(rejectedFiles, '[0].file.name');
			const error_message = _.get(rejectedFiles, '[0].errors[0].message');
			set_show_toast({ state: true, title: `${file_name} Upload failed`, sub_title: error_message });
		}
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div {...getRootProps()}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<p>Drop the files here ...</p>
			) : (
				<Grid
					container
					alignItems={'center'}
					justifyContent={'space-between'}
					border={'1px dashed #D1D6DD'}
					className='uploaded-item'
					flexWrap={'nowrap'}
					sx={{
						cursor: 'pointer',
						position: 'relative',
					}}
					p={2}>
					<Grid>
						<span style={{ fontSize: 14, color: '#A3A3A3' }}>{title}</span>
						<br></br>
						<span style={{ fontSize: 14, color: '#A3A3A3' }}>{sub_title}</span>
					</Grid>
					<Grid textAlign={'right'}>
						<Icon color='#16885F' className='upload-file-icon' iconName='IconPhotoPlus' />
					</Grid>
				</Grid>
			)}
		</div>
	);
}

export default DropZone;
