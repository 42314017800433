import green_tick from './green_tick.json';
import report_loading from './report_loading.json';
import connecting from './account_connect.json';
import loader_animation from './loading_animations.json';
import preview_pdf from './preview_pdf.json';
import insights from './insights.json';
const Animations = {
	green_tick,
	report_loading,
	connecting,
	loader_animation,
	preview_pdf,
	insights,
};

export default Animations;
