import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material';

// type BoxBaseProps = Pick<
// MuiBoxProps
// >;

export interface BoxProps extends MuiBoxProps {}

const Box = ({ children, ...rest }: BoxProps) => <MuiBox {...rest}>{children}</MuiBox>;

Box.defaultProps = {};
export default Box;
