export const response = {
	status: 'success',
	message: 'User form data fetched successfully',
	data: {
		sections: [
			{
				key: 'basic_details',
				name: 'Basic Details',
				priority: 0,
				attributes: [
					{
						key: 'first_name',
						name: 'First Name',
						type: 'text',
						dType: 'text',
						value: 'Test Name',
						priority: 0,
						required: true,
						attribute_id: '0f349df7-7c9b-4b95-b370-e889b63036f2',
					},
					{
						key: 'last_name',
						name: 'Last Name',
						type: 'text',
						dType: 'text',
						value: '',
						priority: 1,
						required: false,
						attribute_id: '0f349df7-7c9b-4b95-b370-e889b63036f2',
					},
					{
						key: 'description',
						name: 'Description',
						type: 'textarea',
						dType: 'textarea',
						value: '',
						priority: 3,
						required: false,
						attribute_id: '0f349df7-7c9b-4b95-b370-e889b63036f2',
					},
					{
						key: 'email',
						name: 'Email',
						type: 'email',
						dType: 'text',
						value: 'test@test.com',
						priority: 3,
						required: true,
						attribute_id: '0f349df7-7c9b-4b95-b370-e889b63036f2',
					},
					{
						key: 'phone',
						name: 'Phone Number',
						type: 'phone',
						dType: 'text',
						value: '',
						priority: 4,
						required: false,
						attribute_id: '0f349df7-7c9b-4b95-b370-e889b63036f2',
					},
					{
						key: 'department',
						name: 'Department',
						type: 'text',
						dType: 'text',
						value: '',
						priority: 5,
						required: false,
						attribute_id: '0f349df7-7c9b-4b95-b370-e889b63036f2',
					},
				],
			},
			{
				key: 'roles',
				name: 'Roles',
				priority: 1,
				attributes: [
					{
						key: 'roles',
						name: 'Roles',
						type: 'select',
						dType: 'select',
						value: 'user',
						priority: 0,
						required: true,
						attribute_id: '0f349df7-7c9b-4b95-b370-e889b63036f2',
						options: [
							{
								key: 'admin',
								label: 'Admin',
								value: 'admin',
							},
							{
								key: 'user',
								label: 'User',
								value: 'user',
							},
						],
					},
				],
			},
			{
				key: 'status',
				name: 'Status',
				priority: 2,
				attributes: [
					{
						key: 'status',
						name: 'Status',
						type: 'select',
						dType: 'select',
						value: 'active',
						priority: 0,
						required: true,
						attribute_id: '0f349df7-7c9b-4b95-b370-e889b63036f2',
						options: [
							{
								key: 'active',
								label: 'Active',
								value: 'active',
							},
							{
								key: 'deactive',
								label: 'De-Active',
								value: 'deactive',
							},
						],
					},
				],
			},
		],
	},
};
