import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

const usePricelist = () => {
	const buyer_catalog_value = useSelector((state: any) => state?.buyer?.catalog?.value);
	const { catalog_mode, catalog_selected_pricelist } = useSelector((state: any) => state?.catalog_mode);

	return catalog_mode && !isEmpty(catalog_selected_pricelist?.value) ? get(catalog_selected_pricelist, 'value', '') : buyer_catalog_value;
};

export default usePricelist;
