import { Image, Grid, Button } from 'src/common/@the-source/atoms';
import ImageLinks from 'src/assets/images/ImageLinks';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import CustomText from '../../CustomText';

interface INoProducts {
	is_filter_applied?: boolean;
	reset_filter_click?: () => any;
}

const NoProducts = ({ is_filter_applied = false, reset_filter_click }: INoProducts) => {
	const [loading, set_loading] = useState(false);
	const reset_click = () => {
		set_loading(true);
		reset_filter_click && reset_filter_click();
	};
	useEffect(() => {
		if (is_filter_applied) {
			set_loading(false);
		}
	}, [is_filter_applied]);

	return (
		<Grid
			container
			sx={{ height: 'calc(100vh - 300px)', minHeight: '420px', overflow: 'hidden' }}
			justifyContent='center'
			alignItems='center'
			direction='column'
			gap='8px'>
			<Image src={ImageLinks.no_products} width={386} height={250} />
			<CustomText type='H2'>{t('Common.ErrorPages.NoResult')}</CustomText>
			<CustomText type='Title'>{t('Common.ErrorPages.Retry')}</CustomText>
			{is_filter_applied && (
				<Button sx={{ margin: '2rem' }} onClick={reset_click} loading={loading}>
					{t('Common.FilterComponents.ClearAll')}
				</Button>
			)}
		</Grid>
	);
};

export default NoProducts;
