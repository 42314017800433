import _ from 'lodash';

import styles from '../Cell.module.css';
import dayjs from 'dayjs';
import constants from 'src/utils/constants';

interface Props {
	value: any;
	valueFormatted?: any;
}

const UnixDateCellRenderer: React.FC<Props> = ({ value, ...rest }) => {
	const { valueFormatted } = rest;
	const formattedDate = _.attempt(() => {
		if (valueFormatted) return dayjs.unix(valueFormatted)?.format(constants.ATTRIBUTE_DATE_FORMAT);
		else return 'NA';
	});

	const safeFormattedDate = _.isError(formattedDate) ? 'NA' : formattedDate;
	return <div className={styles.agGridCustomCell}>{safeFormattedDate}</div>;
};

export default UnixDateCellRenderer;
