// import Loader from "react-loader-spinner";
import LoadingGif from '../../assets/images/loading.gif';
import { Grid } from 'src/common/@the-source/atoms';

const Loading = () => {
	return (
		<Grid container display='flex' width='100%' justifyContent='center'>
			<Grid
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					height: '80vh',
					alignItems: 'center',
				}}>
				<img src={LoadingGif} alt='loadingState' width={200} height={200} />
			</Grid>
		</Grid>
	);
};

export default Loading;
