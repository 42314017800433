import types from 'src/utils/types';

export const error_message = {
	open: true,
	showCross: false,
	anchorOrigin: {
		vertical: types.VERTICAL_TOP,
		horizontal: types.HORIZONTAL_CENTER,
	},
	autoHideDuration: 3000,
	state: types.ERROR_STATE,
	title: types.ERROR_TITLE,
	subtitle: 'Please fill all mandatory fields before proceeding',
	showActions: false,
};
