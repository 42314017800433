import home from './menu/home.svg';
import products from './menu/products.svg';
import sales from './menu/sales.svg';
import buyer from './menu/buyer.svg';
import home_filled from './menu/home_filled.svg';
import products_filled from './menu/products_filled.svg';
import sales_filled from './menu/sales_filled.svg';
import buyer_filled from './menu/buyer_filled.svg';
import logo from './logo.svg';
import mail_setting from './mail_setting.svg';
import email from './icons/email.svg';
import left_arrow from './icons/left_arrow.svg';
import eye from './icons/eye.svg';
import eye_visible from './icons/eye_visible.svg';
import trash from './icons/trash.svg';
import add from './icons/add.svg';
import error from './icons/error.svg';
import avatar from './icons/avatar.svg';
import empty_buyer_list from './empty_buyer_list.svg';
import empty_similar_product_list from './empty_similar_product_list.svg';
import no_products from './icons/no_products.svg';
import no_preview from './no_preview.svg';
import no_data from './no_data.svg';
import file from './icons/file.svg';
import done from './icons/done.svg';
import warning from './icons/warning.svg';
import add_import from './import_data/add.png';
import add_and_update_import from './import_data/add_and_update.png';
import update_import from './import_data/update.png';
import unseen_notification from './icons/unseen_notification.svg';
import no_search_results from './NoSearchResults.svg';
import icon_import_export from './icons/icon_import_export.svg';
import icon_import_export_disabled from './icons/icon_import_export_disabled.svg';
import NoTableResult from 'src/assets/images/NoTableResult.png';
import EmptyBuyerDashboardImage from 'src/assets/images/EmptyBuyerDashboardImage.png';
import ProductNotFound from 'src/assets/images/ProductNotFound.png';
import active_step from 'src/assets/images/icons/active_step.svg';
import info from 'src/assets/images/icons/info.svg';
import inactive_step from 'src/assets/images/icons/step.svg';
import shopify_icon from 'src/assets/images/shopifyIcon.png';
import cart_icon from 'src/assets/images/icons/cart_icon.svg';
import error_500 from 'src/assets/images/error_500.png';
import custom_filter_icon from './custom_filter_icon.svg';
import custom_active_filter_icon from './custom_active_filter_icon.svg';
import SourceWizLogo from './SourceWizLogo.png';
import ErrorLogo from './ErrorLogo.png';
import payment_pending from './payment_pending.svg';
import payment_failed from './payment_failed.gif';
import payment_successful from './payment_successful.gif';
import DiscountIconChecked from '../images/icons/DiscountIconChecked.svg';
import LogoNoText from './logo_icon.png';
import LogoWithText from './LogoWithText.png';
import SparkleLogo from './SparkleLogo.svg';
import disconnect from './disconnect.png';
import connected from './connected.png';
import send_mail from './send_mail.png';
import Terminal from '../images/Terminal.png';
import terminal_icon from '../images/terminal_icon.png';
import advance_payment from '../images/advance_payment.png';
import no_preview_options from './no_preview_options.svg';
import zoho_logo from '../images/zoho.png';
import sos_inventory_logo from '../images/sos_inventory.png';
import sync_now from './sync_now.gif';
import not_found_404 from './404NotFound.png';
import intercom_icon from './intercom_icon.svg';
import empty_catalog from './Empty_catalog.svg';
import insights from './insight.svg';
import insight_white from './insightwhite.svg';
import unchecked from './unchecked.svg';
import checked from './checked.svg';
import intermediate from './intermediate.svg';

const ImageLinks = {
	home,
	logo,
	products,
	sales,
	buyer,
	home_filled,
	products_filled,
	sales_filled,
	buyer_filled,
	email,
	left_arrow,
	eye,
	eye_visible,
	trash,
	add,
	error,
	avatar,
	active_step,
	info,
	inactive_step,
	empty_buyer_list,
	empty_similar_product_list,
	no_products,
	DiscountIconChecked,
	no_preview,
	no_data,
	file,
	done,
	warning,
	add_import,
	add_and_update_import,
	update_import,
	unseen_notification,
	no_search_results,
	icon_import_export,
	icon_import_export_disabled,
	NoTableResult,
	EmptyBuyerDashboardImage,
	ProductNotFound,
	shopify_icon,
	cart_icon,
	error_500,
	custom_filter_icon,
	custom_active_filter_icon,
	SourceWizLogo,
	ErrorLogo,
	LogoNoText,
	LogoWithText,
	SparkleLogo,
	mail_setting,
	disconnect,
	connected,
	send_mail,
	payment_pending,
	payment_failed,
	payment_successful,
	Terminal,
	terminal_icon,
	zoho_logo,
	advance_payment,
	no_preview_options,
	sos_inventory_logo,
	sync_now,
	not_found_404,
	intercom_icon,
	empty_catalog,
	insights,
	insight_white,
	checkbox_unchecked: unchecked,
	checkbox_checked: checked,
	checkbox_intermediate: intermediate,
};

export default ImageLinks;
