export const areaCodes = {
	ca: [
		'204',
		'431',
		'584',
		'250',
		'306',
		'639',
		'474',
		'418',
		'581',
		'367',
		'403',
		'416',
		'647',
		'437',
		'431',
		'450',
		'579',
		'354',
		'506',
		'428',
		'514',
		'438',
		'263',
		'519',
		'226',
		'548',
		'382',
		'587',
		'825',
		'368',
		'600',
		'604',
		'613',
		'343',
		'753',
		'700',
		'705',
		'249',
		'683',
		'709',
		'778',
		'236',
		'672',
		'780',
		'807',
		'819',
		'873',
		'468',
		'867',
		'902',
		'782',
		'905',
		'289',
		'365',
		'742',
	],
	us: [
		'907',
		'205',
		'251',
		'256',
		'334',
		'479',
		'501',
		'870',
		'480',
		'520',
		'602',
		'623',
		'928',
		'209',
		'213',
		'310',
		'323',
		'408',
		'415',
		'510',
		'530',
		'559',
		'562',
		'619',
		'626',
		'650',
		'661',
		'707',
		'714',
		'760',
		'805',
		'818',
		'831',
		'858',
		'909',
		'916',
		'925',
		'949',
		'951',
		'303',
		'719',
		'970',
		'203',
		'860',
		'202',
		'302',
		'239',
		'305',
		'321',
		'352',
		'386',
		'407',
		'561',
		'727',
		'772',
		'813',
		'850',
		'863',
		'904',
		'941',
		'954',
		'229',
		'404',
		'478',
		'706',
		'770',
		'912',
		'808',
		'319',
		'515',
		'563',
		'641',
		'712',
		'208',
		'217',
		'309',
		'312',
		'618',
		'630',
		'708',
		'773',
		'815',
		'847',
		'219',
		'260',
		'317',
		'574',
		'765',
		'812',
		'316',
		'620',
		'785',
		'913',
		'270',
		'502',
		'606',
		'859',
		'225',
		'318',
		'337',
		'504',
		'985',
		'413',
		'508',
		'617',
		'781',
		'978',
		'301',
		'410',
		'207',
		'231',
		'248',
		'269',
		'313',
		'517',
		'586',
		'616',
		'734',
		'810',
		'906',
		'989',
		'218',
		'320',
		'507',
		'612',
		'651',
		'763',
		'952',
		'314',
		'417',
		'573',
		'636',
		'660',
		'816',
		'228',
		'601',
		'662',
		'406',
		'252',
		'336',
		'704',
		'828',
		'910',
		'919',
		'701',
		'308',
		'402',
		'603',
		'201',
		'609',
		'732',
		'856',
		'908',
		'973',
		'505',
		'575',
		'702',
		'775',
		'212',
		'315',
		'516',
		'518',
		'585',
		'607',
		'631',
		'716',
		'718',
		'845',
		'914',
		'216',
		'330',
		'419',
		'440',
		'513',
		'614',
		'740',
		'937',
		'405',
		'580',
		'918',
		'503',
		'541',
		'215',
		'412',
		'570',
		'610',
		'717',
		'724',
		'814',
		'401',
		'803',
		'843',
		'864',
		'605',
		'423',
		'615',
		'731',
		'865',
		'901',
		'931',
		'210',
		'214',
		'254',
		'281',
		'325',
		'361',
		'409',
		'432',
		'512',
		'713',
		'806',
		'817',
		'830',
		'903',
		'915',
		'936',
		'940',
		'956',
		'972',
		'979',
		'435',
		'801',
		'276',
		'434',
		'540',
		'703',
		'757',
		'804',
		'802',
		'206',
		'253',
		'360',
		'425',
		'509',
		'262',
		'414',
		'608',
		'715',
		'920',
		'304',
		'307',
	],
};
