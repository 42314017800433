export const TABLE_CONSTANTS = {
	DASHBOARD_CUSTOM_STATUS_BAR: 'total_value',
	BUYER_CUSTOM_STATUS_BAR: 'Buyers',
	ORDER_LISTING_STATUS_BAR: {
		ALL: 'all',
		ORDERS: 'order',
		QUOTES: 'quote',
		DRAFTS: 'draft',
	},
	TOTAL_VALUE: 'total_value',
	BUYER_STATUS_BAR: {
		ORDERS: 'Order',
		QUOTES: 'Quote',
		DRAFTS: 'Draft',
	},
	ORDER_STATUS_BAR: {
		ORDERS: 'order',
		QUOTES: 'quote',
	},
	ACTION_LINK_ICON: 'link',
	ACTION_DOWNLOAD_ICON: 'download-file',
	ACTION_MENU: 'menu',
	ACTION_EDIT: 'edit',
	PAYMENT_STATUS: ['paid', 'unpaid', 'void', 'pending', 'partially-paid', 'partially_paid', 'partially_refunded', 'refunded', 'overpaid'],
	INTERNAL_STATUS_HEADER_NAME: ['Payment', 'Shipment', 'Invoice'],
};
