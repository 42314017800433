import axios, { AxiosInstance, AxiosResponse } from 'axios';
import constants from './constants';
import { remove_tokens } from './common';
import _ from 'lodash';
import store from 'src/store';

export const instance: AxiosInstance = axios.create({
	baseURL: constants.ENDPOINT,
});

let isRefreshing = false;
let refreshSubscribers: ((access: string) => void)[] = [];

instance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	(error) => {
		const originalRequest = error.config;

		if (error.response && error.response.status === 401 && !error?.config?.url.includes('users/v1/login')) {
			if (!isRefreshing) {
				isRefreshing = true;

				return axios
					.post(`${constants.REFRESH_TOKEN}`, {
						refresh_token: _.get(store.getState(), 'persistedUserData.auth_access_token'),
						token: _.get(store.getState(), 'persistedUserData.auth_access_token'),
					})
					.then((response) => {
						const access = response.data?.access;
						// const refresh = response.data?.refresh;
						// set_auth_cookies(access, refresh);

						refreshSubscribers.forEach((subscriber) => subscriber(access));
						refreshSubscribers = [];

						return axios(originalRequest);
					})
					.catch((error2) => {
						if (error2.request.responseURL === constants.REFRESH_TOKEN) {
							remove_tokens();
							window.location.href = '/';
						}
						return Promise.reject(error);
					})
					.finally(() => {
						isRefreshing = false;
					});
			} else {
				return new Promise<AxiosResponse>((resolve, reject) => {
					refreshSubscribers.push((access) => {
						originalRequest.headers.Authorization = `Bearer ${access}`;

						axios(originalRequest)
							.then((response) => resolve(response))
							// eslint-disable-next-line @typescript-eslint/no-shadow
							.catch((error) => reject(error));
					});
				});
			}
		} else {
			return Promise.reject(error);
		}
	},
);

export default instance;
