export const table_column_def = [
	{
		field: 'label',
		headerName: 'Name',
		flex: 1,
		dtype: 'text',
		editable: false,
		clickable: false,
		minWidth: 300,
		width: 300,
		isVisible: true,
		hideFilter: true,
		cellStyle: {
			minWidth: 300,
		},
	},
	{
		field: 'is_active',
		headerName: 'Is active',
		flex: 1,
		isVisible: true,
		cellRenderer: 'agCheckboxCellRenderer',
		cellEditor: 'agToggleCellEditor',
		hideFilter: true,
		editable: false,
		clickable: false,
		minWidth: 100,
	},
	{
		field: 'is_default',
		headerName: 'Is default',
		flex: 1,
		cellRenderer: 'agCheckboxCellRenderer',
		cellEditor: 'agToggleCellEditor',
		editable: false,
		clickable: false,
		minWidth: 100,
		isVisible: true,
		hideFilter: true,
	},
];
