import { primary } from 'src/utils/light.theme';
import CustomText from '../@the-source/CustomText';
import { Grid } from '../@the-source/atoms';

const CustomizeText = () => {
	return (
		<Grid>
			<CustomText
				type='Micro'
				color={primary[400]}
				style={{
					marginTop: '-0.5rem',
				}}>
				Customize
			</CustomText>
		</Grid>
	);
};

export default CustomizeText;
