export const DISABLED_STATUS = ['Not Generated', 'Failed', 'In Progress'];
export const FAILED_STATUS = 'Failed';
export const IN_PROGRESS = 'In Progress';
export const NOT_GENERATED = 'Not Generated';
export const REVIEW_PRODUCTS_PER_PAGE = 50;

export const ACTION_KEYS = {
	COPY_LINK: 'copy_link',
	DOWNLOAD: 'download',
	EDIT: 'edit',
	REGENERATE: 'regenerate',
};
export const RESET_CATALOG_MODE_PARAMS_KEY = 'reset_catalog_mode';
