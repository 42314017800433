import { makeStyles } from '@mui/styles';
import { info, secondary } from 'src/utils/light.theme';
import { colors } from 'src/utils/theme';

export const useStyles = makeStyles(() => ({
	preview_box: {
		backgroundColor: colors.black_20,
		position: 'fixed',
		left: 0,
		bottom: 0,
		height: '100vh',
		width: '100vw',
		zIndex: 10000,
		display: 'flex',
		alignItems: 'flex-end',
	},
	main_box: {
		backgroundColor: colors.white,
		width: '100%',
		height: 'max-content',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	preview_info: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
		padding: '12px 2vw',
		borderBottom: `1px solid ${colors.dark_midnight_blue}`,
		height: '100%',
	},
	content_container: {
		flex: 1,
	},
	product_info: {
		backgroundColor: info[100],
		borderRadius: '8px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '8px 12px',
		gap: '4px',
	},
	bottom_info: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
		padding: '12px 2vw',
		justifyContent: 'space-between',
		borderTop: `1px solid ${colors.dark_midnight_blue}`,
	},
	preview_icons: {
		position: 'absolute',
		right: '4vw',
		top: '72px',
		display: 'flex',
		flexDirection: 'row',
		zIndex: 10001,
	},
	right_cont: {
		backgroundColor: colors.grey_600,
		display: 'flex',
		width: '100vw',
		height: '80vh',
		alignItems: 'center',
		flexDirection: 'column',
		overflowY: 'scroll',
		paddingTop: '20px',
	},
	label_container: {
		background: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px 12px',
		borderRadius: '8px',
		gap: '4px',
		cursor: 'pointer',
	},
	icon_styles: {
		boxShadow: '0',
		borderRadius: '5px',
		backgroundColor: colors.white,
		margin: '0 0.2vw',
		padding: '8px',
		border: `1px solid ${secondary[400]}`,
		cursor: 'pointer',
	},
	image: {
		width: '100%',
		height: '80vh',
		objectFit: 'contain',
		backgroundColor: colors.grey_600,
		padding: '2vh 0',
		borderRadius: '20px',
	},
	details: {
		padding: '2px',
		backgroundColor: colors.white,
		position: 'absolute',
		top: '50px',
		right: 0,
		borderRadius: '10px',
	},
	file_label: {
		display: 'flex',
		flexDirection: 'row',
		margin: '1vh 0',
		overflow: 'hidden',
	},
	file_data: {
		width: '7vw',
	},
	no_preview: {
		minHeight: '80vh',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: colors.grey_600,
	},
	video: {
		height: '80vh',
		backgroundColor: colors.grey_600,
		display: 'flex',
		alignItems: 'flex-end',
	},
	video_info: {
		width: '100%',
		height: '90%',
	},
}));
