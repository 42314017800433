export const response = {
	status: 'success',
	count: 13,
	data: {
		columns: [
			{
				dtype: 'text',
				field: 'name',
				pinned: 'left',
				editable: false,
				cellStyle: {},
				isVisible: true,
				headerName: 'User Name',
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'text',
				field: 'department',
				editable: false,
				cellStyle: {},
				headerName: 'Department',
				visibility: true,
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'text',
				field: 'role',
				editable: false,
				cellStyle: {},
				headerName: 'Role',
				visibility: true,
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'textarea',
				field: 'description',
				editable: false,
				cellStyle: {},
				headerName: 'Description',
				visibility: true,
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'phone',
				field: 'phone',
				editable: false,
				cellStyle: {},
				headerName: 'Phone Number',
				visibility: true,
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'text',
				field: 'email',
				editable: false,
				cellStyle: {},
				headerName: 'Email',
				visibility: true,
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'text',
				field: 'status',
				editable: false,
				cellStyle: {},
				headerName: 'Status',
				visibility: true,
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'action',
				field: 'action',
				filter: false,
				pinned: 'right',
				editable: false,
				headerName: 'Action',
			},
		],
		rows: [
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'q1w3erxsdsty@qweaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'q1w3erxassdsty@qweaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'q1w3erxassdsty@qweraaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'q1w3erxassdsty@qweraddaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'q1w3erxassdsty@qweraddsdaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'q1w3erxassdsss63ty@qweraddsdaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'q1w3erxassdsss63ty@qwerddsdaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'q1w3erxassdsss63ty@qwerdsdaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'q1w3erxassdsss3ty@qwerdsdaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'qw3erxassdsss3ty@qwerdsdaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'qwerxassdsss3ty@qwerdsdaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'qwerxssdsss3ty@qwerdsdaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
			{
				name: 'ww qq',
				department: '222fdssf',
				role: 'Role1',
				description: 'qqqq',
				phone: '11242343',
				email: 'qwerxsssss3ty@qwerdsdaty.co',
				status: 'active',
				action: [
					{
						name: 'Edit',
						action: 'edit',
						icon: 'IconEdit',
						key: 'edit',
					},
				],
			},
		],
	},
};
