import FallbackImage from 'src/assets/images/fallback.png';

export const HINGE_TYPE = {
	chip: 'chip',
	image_label: 'image_label',
};

export const BUYER = {
	guest: 'guest',
};

export const GUEST_BUYER_ID = '00000000-0000-0000-0000-000000000001';

export const PRODUCT_DETAILS_TYPE = {
	product: 'product',
	variant: 'variant',
};

export const SECTION_TYPE = {
	open_section: 'open_section',
	collapsible: 'collapsible',
};

export const PLACE_HOLDER_IMAGE = FallbackImage;

export const default_image_data: any = [
	{
		id: 1,
		src: FallbackImage,
	},
];
