import React from 'react';
import classes from './Inventory.module.css';
import { Box, Grid } from '../../atoms';
import InventoryMenu from './InventoryMenu';
import { INVENTORY_STATUS, handle_get_menu } from './constants';
import { useSelector } from 'react-redux';
import OutOfStock from '../RecommendCard/OutOfStock';
import CustomText from '../../CustomText';
import { warning } from 'src/utils/light.theme';
import { formatNumberWithCommas } from 'src/utils/common';

interface InventoryStatusProps {
	data: any;
	variantType?: string;
	style?: any;
	fontSize?: number;
	showBg?: boolean;
	disable?: boolean;
}

const InventoryStatus = ({ data, variantType, style, fontSize = 14, showBg, disable }: InventoryStatusProps) => {
	const cart = useSelector((state: any) => state.cart);
	const meta = useSelector((state: any) => state?.settings?.inventory_icon_config);
	const reserved = cart?.document_items?.[data.id]?.total_reserved ?? 0;
	const status = data?.inventory?.inventory_status;
	const total_available = data?.inventory?.total_available ?? 0;
	const out_of_stock_threshold = data?.inventory?.out_of_stock_threshold ?? 0;
	const is_not_in_stock = reserved + total_available <= out_of_stock_threshold;
	const inventory = data?.inventory;
	const is_tracked = inventory?.inventory_tracking_enabled;

	const render_chip_status = () => {
		if (status === INVENTORY_STATUS.out_of_stock && is_not_in_stock) {
			return <OutOfStock style={style} text='Out of stock' />;
		} else if (status === INVENTORY_STATUS.back_order) {
			return <OutOfStock text='Back order' color='rgb(206, 146, 30)' style={{ backgroundColor: `${warning[50]}` }} />;
		} else {
			return null;
		}
	};

	const render_status = () => {
		let status_component;
		switch (status) {
			case INVENTORY_STATUS.out_of_stock:
				if (is_not_in_stock) {
					status_component = (
						<Grid className={classes.parent_container}>
							<Box bgcolor='#FBEDE7' className={classes.status_container} sx={style}>
								<CustomText type='H6' color='#D74C10' className={classes.status_details2} style={{ fontSize }}>
									Out of stock
								</CustomText>
								<InventoryMenu menu_data={handle_get_menu(inventory, meta, reserved)} disable={disable} />
							</Box>
						</Grid>
					);
				}
				break;

			case INVENTORY_STATUS.back_order:
				status_component = (
					<Grid className={classes.parent_container}>
						<Box bgcolor='#FEF7EA' className={classes.status_container} sx={style}>
							<CustomText type='H6' color='#CE921E' className={classes.status_details2} style={{ fontSize }}>
								Back Order
							</CustomText>
							{is_tracked && <InventoryMenu menu_data={handle_get_menu(inventory, meta, reserved)} disable={disable} />}
						</Box>
					</Grid>
				);
				break;

			default:
				status_component = (
					<React.Fragment>
						{is_tracked && (
							<Grid className={classes.parent_container}>
								{showBg ? (
									<Box bgcolor='#F7F8FA' className={classes.status_container}>
										<CustomText type='H6' className={classes.status_details} style={{ fontSize }}>
											{`${meta?.available_quantity?.label}: ${formatNumberWithCommas(data?.inventory?.available_quantity + reserved)}`}
										</CustomText>
										<InventoryMenu menu_data={handle_get_menu(inventory, meta, reserved)} disable={disable} />
									</Box>
								) : (
									<Box display='flex' alignItems='center' gap={0.5}>
										<CustomText type={showBg ? 'H6' : ''} className={classes.status_details} style={{ fontSize }}>
											{`${meta?.available_quantity?.label}: ${formatNumberWithCommas(data?.inventory?.available_quantity + reserved)}`}
										</CustomText>
										<InventoryMenu menu_data={handle_get_menu(inventory, meta, reserved)} disable={disable} />
									</Box>
								)}
							</Grid>
						)}
					</React.Fragment>
				);
		}

		return status_component;
	};

	return <React.Fragment>{variantType === 'chip' ? render_chip_status() : render_status()}</React.Fragment>;
};

export default InventoryStatus;
