import Lottie from 'react-lottie';
import { makeStyles } from '@mui/styles';
import Animations from 'src/assets/animations/Animations';

const useStyles = makeStyles({
	lottieContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 50px)',
		width: '100%',
	},
});

const LoaderScreen = () => {
	const classes = useStyles();
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: Animations.loader_animation,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<div className={classes.lottieContainer}>
			<Lottie options={defaultOptions} height={100} width={100} />
		</div>
	);
};

export default LoaderScreen;
