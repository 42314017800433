import React from 'react';
import { Grid, Typography } from '../../atoms';

const chip_container_style = {
	borderRadius: '40px',
	display: 'flex',
	gap: '0.5rem',
	alignItems: 'center',
	justifyContent: 'center',
	boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.15)',
	color: '#D74C10',
	zIndex: 2,
	left: '5px',
	top: '8px',
	position: 'absolute',
	background: '#F7DBCF',
	padding: '2px 8px',
};

interface Props {
	style?: any;
	text: string;
	color?: string;
}

const OutOfStock: React.FC<Props> = ({ style, text, color = '#D74C10' }) => {
	return (
		<React.Fragment>
			<Grid sx={{ ...chip_container_style, ...style }}>
				<Typography
					color={color}
					sx={{
						fontSize: '11px',
						fontWeight: '700',
					}}>
					{text}
				</Typography>
			</Grid>
		</React.Fragment>
	);
};

export default OutOfStock;
