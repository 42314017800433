export const response = {
	message: 'Ok',
	status_code: 200,
	success: true,
	data: {
		hits: [
			{
				id: 'c4d62603-7a23-4678-b714-c82bc93f7d85',
				name: 'Velentina Coverlet',
				type: 'variant',
				sku_id: 'CL-251-9496',
				tenant_id: '0f349df7-7c9b-4b95-b370-e889b63032f2',
				parent_id: 'f2f139f3-2457-4a43-8c1a-9ba6fa8fccf9',
				search_string: ['weight', '8.5', 'lbs', 'color', 'Blue', 'dimensions', '94X96', 'Inch', 'size', 'Queen'],
				custom_attributes: [
					{
						id: '8c170d99-f045-483f-bcdb-d3d8ae3cc00d',
						name: 'other_information',
						type: 'textarea',
						value:
							'Use a Large Capacity Washer & Dryer, Machine Wash In Cold Water Separately, Gentle Cycle, Do Not Bleach, Tumble Dry Low, Remove Promptly, Warm Iron',
					},
					{
						id: '920acaa3-1c2b-465c-84c3-4b31dc62402e',
						name: 'description',
						type: 'textarea',
						value:
							'Dress Your Bed In Our Signature Diamond Quilted Velentina Coverlet And Shams For Luxury And Comfort. This Collection Has Been Created With A Perfect Weight For Layering And Is A Classic Staple And Pairs Perfectly With Various Patterns And Prints. This Collection Is Made From 100% Cotton Sateen 300 Thread Count.',
					},
					{
						id: 'cdcc3756-0cf9-4004-ba62-1856ae8dd7ca',
						name: 'weight',
						type: 'text',
						value: '8.5 lbs',
					},
					{
						id: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
						name: 'color',
						type: 'select',
						value: 'Blue',
					},
					{
						id: 'fc420b17-3d1b-4f68-b218-354567e45887',
						name: 'dimensions',
						type: 'text',
						value: '94X96 Inch',
					},
					{
						id: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
						name: 'size',
						type: 'select',
						value: 'Queen',
					},
				],
				inventory: {
					min_order_quantity: 5,
					incremental_value: 1,
					max_order_quantity: 100,
					stock: 200.0,
					name: 'In Stock : 200.0 pcs',
					box_color: 'primary',
					show_stock_list: 'true',
					list: {
						in_stock: '200.0 pcs',
						on_hold: '',
						on_loom: '',
					},
				},
				created_at: '2023-07-26T19:18:12.390430',
				updated_at: '2023-07-26T19:25:02.038914',
				media: [
					{
						id: '1',
						url: 'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-251-9496_1.jpg',
						type: 'image',
						is_primary: true,
					},
					{
						id: '2',
						url: 'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-251-9496_3.jpg',
						type: 'image',
						is_primary: false,
					},
				],
				category: [
					{
						name: 'Coverlet',
						parent_id: '9782b017-7483-44ee-8417-84ad3cc97926',
						id: 'f8593c72-3a5b-45c3-8983-5b7031a34a32',
						children: null,
						level: 2,
						path: '9782b017-7483-44ee-8417-84ad3cc97926,9782b017-7483-44ee-8417-84ad3cc97926',
					},
					{
						children:
							'e15db9f0-77b0-4e92-b306-df454ab2bd32,b450f35a-cf66-4d1c-843b-b7cb99647d6a,14f71612-893f-4bce-aade-3b77f1390dd5,f8593c72-3a5b-45c3-8983-5b7031a34a32,b68bbc26-40ed-4ee7-b83b-ccaddfe3bca0,fb02b773-f72a-47af-ab77-8f7884c964b0',
						parent_id: '9782b017-7483-44ee-8417-84ad3cc97926',
						name: 'Bedding',
						path: '9782b017-7483-44ee-8417-84ad3cc97926',
						level: 1,
						id: '9782b017-7483-44ee-8417-84ad3cc97926',
					},
					{
						children:
							'e15db9f0-77b0-4e92-b306-df454ab2bd32,b450f35a-cf66-4d1c-843b-b7cb99647d6a,14f71612-893f-4bce-aade-3b77f1390dd5,f8593c72-3a5b-45c3-8983-5b7031a34a32,b68bbc26-40ed-4ee7-b83b-ccaddfe3bca0,fb02b773-f72a-47af-ab77-8f7884c964b0',
						parent_id: '9782b017-7483-44ee-8417-84ad3cc97926',
						name: 'Bedding',
						path: '9782b017-7483-44ee-8417-84ad3cc97926',
						level: 1,
						id: '9782b017-7483-44ee-8417-84ad3cc97926',
					},
				],
				collections: [],
				meta: null,
				variants_meta: {
					hinges_value_map: {
						'014c1ba0-c885-4cdc-b589-62d68f3f66c8': [
							{
								value: 'Mint',
								priority: 0,
								type: 'image_label',
								type_value: 'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-252-9496_1.jpg',
							},
							{
								value: 'Blue',
								priority: 0,
								type: 'image_label',
								type_value: 'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-251-9496_1.jpg',
							},
						],
						'f52bedd2-a7f1-46bf-acd4-addcd69ffac9': [
							{
								value: 'King',
								priority: 0,
								type: 'chip',
								type_value: '',
							},
							{
								value: 'Queen',
								priority: 0,
								type: 'chip',
								type_value: '',
							},
						],
					},
					variant_data_map: [
						{
							'014c1ba0-c885-4cdc-b589-62d68f3f66c8': 'Mint',
							'f52bedd2-a7f1-46bf-acd4-addcd69ffac9': 'King',
							product_id: 'af9fcbb4-6e0e-4396-ba2a-ee0de427f544',
						},
						{
							'014c1ba0-c885-4cdc-b589-62d68f3f66c8': 'Blue',
							'f52bedd2-a7f1-46bf-acd4-addcd69ffac9': 'Queen',
							product_id: 'c4d62603-7a23-4678-b714-c82bc93f7d85',
						},
					],
					hinge_attributes: [
						{
							id: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
							name: 'color',
							priority: 0,
						},
						{
							id: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
							name: 'size',
							priority: 0,
						},
					],
				},
				transformed_attributes: [
					{
						id: '8c170d99-f045-483f-bcdb-d3d8ae3cc00d',
						dType: 'textarea',
						isInternal: false,
						label: 'other_information',
						value:
							'Use a Large Capacity Washer & Dryer, Machine Wash In Cold Water Separately, Gentle Cycle, Do Not Bleach, Tumble Dry Low, Remove Promptly, Warm Iron',
						composite: {},
						values: [
							'Use a Large Capacity Washer & Dryer, Machine Wash In Cold Water Separately, Gentle Cycle, Do Not Bleach, Tumble Dry Low, Remove Promptly, Warm Iron',
						],
					},
					{
						id: '920acaa3-1c2b-465c-84c3-4b31dc62402e',
						dType: 'textarea',
						isInternal: false,
						label: 'description',
						value:
							'Dress Your Bed In Our Signature Diamond Quilted Velentina Coverlet And Shams For Luxury And Comfort. This Collection Has Been Created With A Perfect Weight For Layering And Is A Classic Staple And Pairs Perfectly With Various Patterns And Prints. This Collection Is Made From 100% Cotton Sateen 300 Thread Count.',
						composite: {},
						values: [
							'Dress Your Bed In Our Signature Diamond Quilted Velentina Coverlet And Shams For Luxury And Comfort. This Collection Has Been Created With A Perfect Weight For Layering And Is A Classic Staple And Pairs Perfectly With Various Patterns And Prints. This Collection Is Made From 100% Cotton Sateen 300 Thread Count.',
						],
					},
					{
						id: 'cdcc3756-0cf9-4004-ba62-1856ae8dd7ca',
						dType: 'text',
						isInternal: false,
						label: 'weight',
						value: '8.5 lbs',
						composite: {},
						values: ['8.5 lbs'],
					},
					{
						id: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
						dType: 'select',
						isInternal: false,
						label: 'color',
						value: 'Blue',
						composite: {},
						values: ['Blue'],
					},
					{
						id: 'fc420b17-3d1b-4f68-b218-354567e45887',
						dType: 'text',
						isInternal: false,
						label: 'dimensions',
						value: '94X96 Inch',
						composite: {},
						values: ['94X96 Inch'],
					},
					{
						id: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
						dType: 'select',
						isInternal: false,
						label: 'size',
						value: 'Queen',
						composite: {},
						values: ['Queen'],
					},
				],
				pricing: {
					pricing_rule: {
						volume: {
							name: 'Volume Discount',
							type: 'Volume',
							tiers: [],
						},
					},
					product_id: 'c4d62603-7a23-4678-b714-c82bc93f7d85',
					price: 600.0,
					unit: 'per-piece',
					price_level_id: '01d11338-4674-4643-a013-f18c5e579d93',
					id: 'f22e02e1-062d-460a-b28c-28def0e8cd82',
					currency: '$',
					variant_price_range: {
						min_value: 600,
						max_value: 720,
					},
				},
			},
			{
				id: 'af9fcbb4-6e0e-4396-ba2a-ee0de427f544',
				name: 'Velentina Coverlet',
				type: 'variant',
				sku_id: 'CL-251-11096',
				tenant_id: '0f349df7-7c9b-4b95-b370-e889b63032f2',
				parent_id: 'f2f139f3-2457-4a43-8c1a-9ba6fa8fccf9',
				search_string: ['weight', '8.5', 'lbs', 'color', 'Mint', 'dimensions', '94X96', 'Inch', 'size', 'King'],
				custom_attributes: [
					{
						id: '8c170d99-f045-483f-bcdb-d3d8ae3cc00d',
						name: 'other_information',
						type: 'textarea',
						value:
							'Use a Large Capacity Washer & Dryer, Machine Wash In Cold Water Separately, Gentle Cycle, Do Not Bleach, Tumble Dry Low, Remove Promptly, Warm Iron',
					},
					{
						id: '920acaa3-1c2b-465c-84c3-4b31dc62402e',
						name: 'description',
						type: 'textarea',
						value:
							'Dress Your Bed In Our Signature Diamond Quilted Velentina Coverlet And Shams For Luxury And Comfort. This Collection Has Been Created With A Perfect Weight For Layering And Is A Classic Staple And Pairs Perfectly With Various Patterns And Prints. This Collection Is Made From 100% Cotton Sateen 300 Thread Count.',
					},
					{
						id: 'cdcc3756-0cf9-4004-ba62-1856ae8dd7ca',
						name: 'weight',
						type: 'text',
						value: '8.5 lbs',
					},
					{
						id: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
						name: 'color',
						type: 'select',
						value: 'Mint',
					},
					{
						id: 'fc420b17-3d1b-4f68-b218-354567e45887',
						name: 'dimensions',
						type: 'text',
						value: '94X96 Inch',
					},
					{
						id: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
						name: 'size',
						type: 'select',
						value: 'King',
					},
				],
				inventory: {
					min_order_quantity: 5,
					incremental_value: 1,
					max_order_quantity: 100,
					stock: 200.0,
					name: 'In Stock : 200 pcs',
					box_color: 'primary',
					show_stock_list: 'true',
					list: {
						in_stock: '200 pcs',
						on_hold: '',
						on_loom: '',
					},
				},
				created_at: '2023-07-26T19:18:12.390430',
				updated_at: '2023-07-26T19:25:02.038914',
				media: [
					{
						id: '1',
						url: 'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-252-9496_1.jpg',
						type: 'image',
						is_primary: true,
					},
					{
						id: '2',
						url: 'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-252-9496_2.jpg',
						type: 'image',
						is_primary: false,
					},
				],
				category: [
					{
						level: 2,
						name: 'Coverlet',
						id: 'f8593c72-3a5b-45c3-8983-5b7031a34a32',
						parent_id: '9782b017-7483-44ee-8417-84ad3cc97926',
						path: '9782b017-7483-44ee-8417-84ad3cc97926,9782b017-7483-44ee-8417-84ad3cc97926',
						children: null,
					},
					{
						children:
							'e15db9f0-77b0-4e92-b306-df454ab2bd32,b450f35a-cf66-4d1c-843b-b7cb99647d6a,14f71612-893f-4bce-aade-3b77f1390dd5,f8593c72-3a5b-45c3-8983-5b7031a34a32,b68bbc26-40ed-4ee7-b83b-ccaddfe3bca0,fb02b773-f72a-47af-ab77-8f7884c964b0',
						parent_id: '9782b017-7483-44ee-8417-84ad3cc97926',
						name: 'Bedding',
						path: '9782b017-7483-44ee-8417-84ad3cc97926',
						level: 1,
						id: '9782b017-7483-44ee-8417-84ad3cc97926',
					},
					{
						children:
							'e15db9f0-77b0-4e92-b306-df454ab2bd32,b450f35a-cf66-4d1c-843b-b7cb99647d6a,14f71612-893f-4bce-aade-3b77f1390dd5,f8593c72-3a5b-45c3-8983-5b7031a34a32,b68bbc26-40ed-4ee7-b83b-ccaddfe3bca0,fb02b773-f72a-47af-ab77-8f7884c964b0',
						parent_id: '9782b017-7483-44ee-8417-84ad3cc97926',
						name: 'Bedding',
						path: '9782b017-7483-44ee-8417-84ad3cc97926',
						level: 1,
						id: '9782b017-7483-44ee-8417-84ad3cc97926',
					},
				],
				collections: [],
				meta: {
					sku_id: 'CL-251-11096',
					parent_id: 'CL-251',
					name: 'Velentina Coverlet',
					hinge_attributes: 'color,size',
					color: 'Mint',
					size: 'King',
					category_id: 12,
					image_list:
						'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-252-9496_1.jpg,https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-252-9496_2.jpg',
					variant_image: 'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-252-9496_1.jpg',
					collection_id: '',
					description:
						'Dress Your Bed In Our Signature Diamond Quilted Velentina Coverlet And Shams For Luxury And Comfort. This Collection Has Been Created With A Perfect Weight For Layering And Is A Classic Staple And Pairs Perfectly With Various Patterns And Prints. This Collection Is Made From 100% Cotton Sateen 300 Thread Count.',
					currency: '$',
					price: 720,
					unit: 'per-piece',
					minimum_order_quantity: 5,
					max_order_quantity: 100,
					step_increment: 1,
					in_stock: 200,
					dimensions: '94X96 Inch',
					weight: '8.5 lbs',
					other_information:
						'Use a Large Capacity Washer & Dryer, Machine Wash In Cold Water Separately, Gentle Cycle, Do Not Bleach, Tumble Dry Low, Remove Promptly, Warm Iron',
					similar_sku: '',
					fbt_sku: '',
				},
				variants_meta: {
					hinges_value_map: {
						'014c1ba0-c885-4cdc-b589-62d68f3f66c8': [
							{
								value: 'Mint',
								priority: 0,
								type: 'image_label',
								type_value: 'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-252-9496_1.jpg',
							},
							{
								value: 'Blue',
								priority: 0,
								type: 'image_label',
								type_value: 'https://orchidsluxhome.com/wp-content/uploads/2023/01/CL-251-9496_1.jpg',
							},
						],
						'f52bedd2-a7f1-46bf-acd4-addcd69ffac9': [
							{
								value: 'King',
								priority: 0,
								type: 'chip',
								type_value: '',
							},
							{
								value: 'Queen',
								priority: 0,
								type: 'chip',
								type_value: '',
							},
						],
					},
					variant_data_map: [
						{
							'014c1ba0-c885-4cdc-b589-62d68f3f66c8': 'Mint',
							'f52bedd2-a7f1-46bf-acd4-addcd69ffac9': 'King',
							product_id: 'af9fcbb4-6e0e-4396-ba2a-ee0de427f544',
						},
						{
							'014c1ba0-c885-4cdc-b589-62d68f3f66c8': 'Blue',
							'f52bedd2-a7f1-46bf-acd4-addcd69ffac9': 'Queen',
							product_id: 'c4d62603-7a23-4678-b714-c82bc93f7d85',
						},
					],
					hinge_attributes: [
						{
							id: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
							name: 'color',
							priority: 0,
						},
						{
							id: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
							name: 'size',
							priority: 0,
						},
					],
				},
				transformed_attributes: [
					{
						id: '8c170d99-f045-483f-bcdb-d3d8ae3cc00d',
						dType: 'textarea',
						isInternal: false,
						label: 'other_information',
						value:
							'Use a Large Capacity Washer & Dryer, Machine Wash In Cold Water Separately, Gentle Cycle, Do Not Bleach, Tumble Dry Low, Remove Promptly, Warm Iron',
						composite: {},
						values: [
							'Use a Large Capacity Washer & Dryer, Machine Wash In Cold Water Separately, Gentle Cycle, Do Not Bleach, Tumble Dry Low, Remove Promptly, Warm Iron',
						],
					},
					{
						id: '920acaa3-1c2b-465c-84c3-4b31dc62402e',
						dType: 'textarea',
						isInternal: false,
						label: 'description',
						value:
							'Dress Your Bed In Our Signature Diamond Quilted Velentina Coverlet And Shams For Luxury And Comfort. This Collection Has Been Created With A Perfect Weight For Layering And Is A Classic Staple And Pairs Perfectly With Various Patterns And Prints. This Collection Is Made From 100% Cotton Sateen 300 Thread Count.',
						composite: {},
						values: [
							'Dress Your Bed In Our Signature Diamond Quilted Velentina Coverlet And Shams For Luxury And Comfort. This Collection Has Been Created With A Perfect Weight For Layering And Is A Classic Staple And Pairs Perfectly With Various Patterns And Prints. This Collection Is Made From 100% Cotton Sateen 300 Thread Count.',
						],
					},
					{
						id: 'cdcc3756-0cf9-4004-ba62-1856ae8dd7ca',
						dType: 'text',
						isInternal: false,
						label: 'weight',
						value: '8.5 lbs',
						composite: {},
						values: ['8.5 lbs'],
					},
					{
						id: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
						dType: 'select',
						isInternal: false,
						label: 'color',
						value: 'Mint',
						composite: {},
						values: ['Mint'],
					},
					{
						id: 'fc420b17-3d1b-4f68-b218-354567e45887',
						dType: 'text',
						isInternal: false,
						label: 'dimensions',
						value: '94X96 Inch',
						composite: {},
						values: ['94X96 Inch'],
					},
					{
						id: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
						dType: 'select',
						isInternal: false,
						label: 'size',
						value: 'King',
						composite: {},
						values: ['King'],
					},
				],
				pricing: {
					pricing_rule: {
						volume: {
							name: 'Volume Discount',
							type: 'Volume',
							tiers: [],
						},
					},
					product_id: 'af9fcbb4-6e0e-4396-ba2a-ee0de427f544',
					price: 720.0,
					unit: 'per-piece',
					price_level_id: '01d11338-4674-4643-a013-f18c5e579d93',
					id: '65838bfa-c50f-493f-bbc9-3320fa113aec',
					currency: '$',
					variant_price_range: {
						min_value: 600.0,
						max_value: 720,
					},
				},
			},
		],
		facets: {
			categories: {
				doc_count: 6,
				category: {
					doc_count_error_upper_bound: 0,
					sum_other_doc_count: 0,
					buckets: [
						{
							key: '9782b017-7483-44ee-8417-84ad3cc97926',
							doc_count: 4,
						},
						{
							key: 'f8593c72-3a5b-45c3-8983-5b7031a34a32',
							doc_count: 2,
						},
					],
				},
			},
			collections: {
				doc_count: 0,
				collections: {
					doc_count_error_upper_bound: 0,
					sum_other_doc_count: 0,
					buckets: [],
				},
			},
			attributes: {
				doc_count: 12,
				ids: {
					doc_count_error_upper_bound: 0,
					sum_other_doc_count: 0,
					buckets: {
						'014c1ba0-c885-4cdc-b589-62d68f3f66c8': {
							key: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
							doc_count: 2,
							date_range: {
								count: 0,
								min: null,
								max: null,
								avg: null,
								sum: 0.0,
							},
							number_range: {
								count: 0,
								min: null,
								max: null,
								avg: null,
								sum: 0.0,
							},
							values: {
								doc_count_error_upper_bound: 0,
								sum_other_doc_count: 0,
								buckets: [
									{
										key: 'Blue',
										doc_count: 1,
									},
									{
										key: 'Mint',
										doc_count: 1,
									},
								],
							},
						},
						'cdcc3756-0cf9-4004-ba62-1856ae8dd7ca': {
							key: 'cdcc3756-0cf9-4004-ba62-1856ae8dd7ca',
							doc_count: 2,
							date_range: {
								count: 0,
								min: null,
								max: null,
								avg: null,
								sum: 0.0,
							},
							number_range: {
								count: 0,
								min: null,
								max: null,
								avg: null,
								sum: 0.0,
							},
							values: {
								doc_count_error_upper_bound: 0,
								sum_other_doc_count: 0,
								buckets: [
									{
										key: '8.5 lbs',
										doc_count: 2,
									},
								],
							},
						},
						'f52bedd2-a7f1-46bf-acd4-addcd69ffac9': {
							key: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
							doc_count: 2,
							date_range: {
								count: 0,
								min: null,
								max: null,
								avg: null,
								sum: 0.0,
							},
							number_range: {
								count: 0,
								min: null,
								max: null,
								avg: null,
								sum: 0.0,
							},
							values: {
								doc_count_error_upper_bound: 0,
								sum_other_doc_count: 0,
								buckets: [
									{
										key: 'King',
										doc_count: 1,
									},
									{
										key: 'Queen',
										doc_count: 1,
									},
								],
							},
						},
						'fc420b17-3d1b-4f68-b218-354567e45887': {
							key: 'fc420b17-3d1b-4f68-b218-354567e45887',
							doc_count: 2,
							date_range: {
								count: 0,
								min: null,
								max: null,
								avg: null,
								sum: 0.0,
							},
							number_range: {
								count: 0,
								min: null,
								max: null,
								avg: null,
								sum: 0.0,
							},
							values: {
								doc_count_error_upper_bound: 0,
								sum_other_doc_count: 0,
								buckets: [
									{
										key: '94X96 Inch',
										doc_count: 2,
									},
								],
							},
						},
					},
				},
			},
			updated_at: {
				count: 2,
				min: 1690399502038.0,
				max: 1690399502038.0,
				avg: 1690399502038.0,
				sum: 3380799004076.0,
				min_as_string: '2023-07-26T19:25:02.038Z',
				max_as_string: '2023-07-26T19:25:02.038Z',
				avg_as_string: '2023-07-26T19:25:02.038Z',
				sum_as_string: '2077-02-17T14:50:04.076Z',
			},
			price: {
				count: 2,
				min: 600.0,
				max: 720.0,
				avg: 660.0,
				sum: 1320.0,
			},
			created_at: {
				count: 2,
				min: 1690399092390.0,
				max: 1690399092390.0,
				avg: 1690399092390.0,
				sum: 3380798184780.0,
				min_as_string: '2023-07-26T19:18:12.390Z',
				max_as_string: '2023-07-26T19:18:12.390Z',
				avg_as_string: '2023-07-26T19:18:12.390Z',
				sum_as_string: '2077-02-17T14:36:24.780Z',
			},
		},
		nbHits: 2,
		page: 1,
		nbPages: 1,
		hitsPerPage: 100,
	},
	paginator: {},
};
