import { secondary } from '../../../../../utils/light.theme';
import { Box, Icon, Typography } from '../../../atoms';
import styles from './FilterIcon.module.css';

export interface FilterIconProps {
	onClick?: () => void;
	btnPrefix?: string;
}

const FilterIcon = ({ onClick, btnPrefix }: FilterIconProps) => (
	<Box onClick={onClick} className={styles['icon-container']}>
		<Typography color='rgba(0, 0, 0, 0.87)' variant='body1'>
			{btnPrefix}
		</Typography>
		<Icon iconName='IconFilter' fontSize='small' color={secondary[700]} />
	</Box>
);

FilterIcon.defaultProps = {
	onClick: () => {},
	btnPrefix: '',
};
export default FilterIcon;
