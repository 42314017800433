export const response_columns = {
	message: 'Ok',
	status_code: 200,
	success: true,
	data: {
		columns: [
			{
				dtype: 'text',
				field: 'name',
				pinned: 'left',
				editable: false,
				cellStyle: {},
				isVisible: true,
				headerName: 'Catalog name',
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'status',
				field: 'status',
				editable: false,
				cellStyle: {},
				isVisible: true,
				headerName: 'Status',
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'number',
				field: 'products_count',
				editable: false,
				cellStyle: {},
				isVisible: true,
				headerName: 'Products',
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'number',
				field: 'buyers_count',
				editable: false,
				cellStyle: {},
				isVisible: true,
				headerName: 'Customers',
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'date',
				field: 'created_at',
				editable: false,
				cellStyle: {},
				isVisible: true,
				headerName: 'Created on',
				headerStyle: {},
				filterParams: [],
			},
			{
				dtype: 'date',
				field: 'updated_at',
				editable: false,
				cellStyle: {},
				isVisible: true,
				headerName: 'Last updated on',
				headerStyle: {},
				filterParams: [],
			},
		],
		rows: [
			{
				products_count: null,
				buyers_count: null,
				id: '8ddb7730-b969-4e4a-9fe8-92955c5e16c5',
				name: 'Test catalog',
				description: 'this is a test',
				currency: 'USD',
				status: 'active',
				created_at: '2023-09-27T06:26:36.772625',
				updated_at: '2023-09-27T06:30:18.619418',
			},
			{
				products_count: 2,
				buyers_count: 1,
				id: '43cabb2c-626a-4518-8079-9b044bee33ea',
				name: 'Default',
				description: 'Default',
				currency: '$',
				status: 'active',
				created_at: '2023-09-21T09:44:41.836158',
				updated_at: '2023-09-21T09:44:41.836158',
			},
			{
				products_count: 2,
				buyers_count: 1,
				id: '33cabb2c-626a-4518-8079-9b044bee33ea',
				name: 'Retail',
				description: 'For retail customers',
				currency: '$',
				status: 'active',
				created_at: '2023-09-21T09:44:41.836158',
				updated_at: '2023-09-21T09:44:41.836158',
			},
			{
				products_count: 2,
				buyers_count: 1,
				id: 'ad4518df-e384-4e7a-9401-0c8cb6abfc48',
				name: 'Wholesale',
				description: 'For Wholesale customers',
				currency: '$',
				status: 'active',
				created_at: '2023-09-21T08:01:47.745199',
				updated_at: '2023-09-21T08:01:47.745199',
			},
		],
	},
	paginator: {},
};

export const response_product_columns = {
	message: 'Ok',
	status_code: 200,
	success: true,
	data: [
		{
			headerName: 'SKU ID',
			field: 'sku_id',
			headerStyle: {},
			cellStyle: {},
			dtype: 'text',
			visibility: true,
			pinned: 'left',
			lockPinned: true,
			flex: 1,
			minWidth: 200,
			suppressSizeToFit: true,
			autoSize: true,
		},
		{
			headerName: 'Image',
			field: 'media.1.url',
			dtype: 'image',
			headerStyle: {},
			cellStyle: {},
			visibility: true,
			flex: 1,
			minWidth: 120,
			suppressSizeToFit: true,
			autoSize: true,
			sortable: false,
			filter: false,
			filterParams: {
				maxNumConditions: 1,
			},
		},
		{
			headerName: 'Name',
			field: 'name',
			dtype: 'text',
			headerStyle: {},
			cellStyle: {},
			visibility: true,
			flex: 1,
			minWidth: 200,
			suppressSizeToFit: true,
			autoSize: true,
			sortable: false,
			filterParams: {
				maxNumConditions: 1,
			},
		},
		{
			headerName: 'Description',
			field: 'description',
			dtype: 'text',
			headerStyle: {},
			cellStyle: {},
			visibility: true,
			flex: 1,
			minWidth: 200,
			suppressSizeToFit: true,
			autoSize: true,
			sortable: false,
			filterParams: {
				maxNumConditions: 1,
			},
		},
		{
			headerName: 'Category',
			field: 'category',
			dtype: 'text',
			headerStyle: {},
			cellStyle: {},
			visibility: true,
			flex: 1,
			minWidth: 200,
			suppressSizeToFit: true,
			autoSize: true,
			filterParams: {
				maxNumConditions: 1,
			},
		},
		{
			headerName: 'Collection',
			field: 'collenction',
			dtype: 'text',
			headerStyle: {},
			cellStyle: {},
			visibility: true,
			flex: 1,
			minWidth: 200,
			suppressSizeToFit: true,
			autoSize: true,
			filterParams: {
				maxNumConditions: 1,
			},
		},
		{
			headerName: 'Price',
			field: 'price',
			headerStyle: {},
			cellStyle: {},
			visibility: true,
			dtype: 'currency',
			filterValues: [],
			editable: false,
			flex: 1,
			minWidth: 200,
			suppressSizeToFit: true,
			autoSize: true,
			filterParams: {
				maxNumConditions: 1,
			},
			hideFilter: true,
		},
		{
			headerName: 'MOQ',
			field: 'moq',
			headerStyle: {},
			cellStyle: {},
			visibility: true,
			dtype: 'number',
			filterValues: [],
			editable: false,
			flex: 1,
			minWidth: 200,
			suppressSizeToFit: true,
			autoSize: true,
			filterParams: {
				maxNumConditions: 1,
			},
			hideFilter: true,
		},
		{
			headerName: 'IOQ',
			field: 'ioq',
			headerStyle: {},
			cellStyle: {},
			visibility: true,
			dtype: 'number',
			filterValues: [],
			editable: false,
			flex: 1,
			minWidth: 200,
			suppressSizeToFit: true,
			autoSize: true,
			filterParams: {
				maxNumConditions: 1,
			},
			hideFilter: true,
		},
		{
			headerName: 'Max. Quantity',
			field: 'max_quantity',
			headerStyle: {},
			cellStyle: {},
			visibility: true,
			dtype: 'number',
			filterValues: [],
			editable: false,
			flex: 1,
			minWidth: 200,
			suppressSizeToFit: true,
			autoSize: true,
			filterParams: {
				maxNumConditions: 1,
			},
			hideFilter: true,
		},
	],
	paginator: {},
	status: 200,
};

export const response_product_datasource = {
	message: 'Ok',
	status_code: 200,
	success: true,
	data: {
		data: [
			{
				sku_id: 'SKU12345',
				document_status: 'active',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				name: 'Product A',
				description: 'This is a description for Product A',
				category: 'Electronics',
				collenction: 'Summer 2023', // Note: You might have a typo in your field name, probably meant "collection"
				price: '199.99',
				moq: 100, // Minimum Order Quantity
				ioq: 50, // Inventory Order Quantity (assumed naming based on context)
				max_quantity: 1000,
			},
			{
				sku_id: 'SKU12346',
				document_status: 'inactive',
				name: 'Product B',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				description: 'This is a description for Product B',
				category: 'Apparel',
				collenction: 'Winter 2023',
				price: '49.99',
				moq: 50,
				ioq: 25,
				max_quantity: 500,
			},
			{
				sku_id: 'SKU12345',
				document_status: 'active',
				name: 'Product A',
				description: 'This is a description for Product A',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				category: 'Electronics',
				collenction: 'Summer 2023', // Note: You might have a typo in your field name, probably meant "collection"
				price: '199.99',
				moq: 100, // Minimum Order Quantity
				ioq: 50, // Inventory Order Quantity (assumed naming based on context)
				max_quantity: 1000,
			},
			{
				sku_id: 'SKU12346',
				document_status: 'inactive',
				name: 'Product B',
				description: 'This is a description for Product B',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				category: 'Apparel',
				collenction: 'Winter 2023',
				price: '49.99',
				moq: 50,
				ioq: 25,
				max_quantity: 500,
			},
			{
				sku_id: 'SKU12345',
				document_status: 'active',
				name: 'Product A',
				description: 'This is a description for Product A',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				category: 'Electronics',
				collenction: 'Summer 2023', // Note: You might have a typo in your field name, probably meant "collection"
				price: '199.99',
				moq: 100, // Minimum Order Quantity
				ioq: 50, // Inventory Order Quantity (assumed naming based on context)
				max_quantity: 1000,
			},
			{
				sku_id: 'SKU12346',
				document_status: 'inactive',
				name: 'Product B',
				description: 'This is a description for Product B',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				category: 'Apparel',
				collenction: 'Winter 2023',
				price: '49.99',
				moq: 50,
				ioq: 25,
				max_quantity: 500,
			},
			{
				sku_id: 'SKU12346',
				document_status: 'inactive',
				name: 'Product B',
				description: 'This is a description for Product B',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				category: 'Apparel',
				collenction: 'Winter 2023',
				price: '49.99',
				moq: 50,
				ioq: 25,
				max_quantity: 500,
			},
			{
				sku_id: 'SKU12346',
				document_status: 'inactive',
				name: 'Product B',
				description: 'This is a description for Product B',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				category: 'Apparel',
				collenction: 'Winter 2023',
				price: '49.99',
				moq: 50,
				ioq: 25,
				max_quantity: 500,
			},
			{
				sku_id: 'SKU12346',
				document_status: 'inactive',
				name: 'Product B',
				description: 'This is a description for Product B',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				category: 'Apparel',
				collenction: 'Winter 2023',
				price: '49.99',
				moq: 50,
				ioq: 25,
				max_quantity: 500,
			},
			{
				sku_id: 'SKU12345',
				document_status: 'active',
				name: 'Product A',
				description: 'This is a description for Product A',
				'media.1.url': 'https://res.cloudinary.com/sourcewiz/image/upload/q_auto/bd6bb341-8b19-4217-b70a-463b76d990de.jpg',
				category: 'Electronics',
				collenction: 'Summer 2023', // Note: You might have a typo in your field name, probably meant "collection"
				price: '199.99',
				moq: 100, // Minimum Order Quantity
				ioq: 50, // Inventory Order Quantity (assumed naming based on context)
				max_quantity: 1000,
			},
		],
	},
	total: 10,
	startRow: 0,
	endRow: 100,
	paginator: {},
	status: 200,
};

export const response_options = {
	message: 'Ok',
	status_code: 200,
	success: true,
	data: [
		{ value: 'catalog_138435', label: 'Catalog 1' },
		{ value: 'catalog_1383435', label: 'Catalog 2' },
		{ value: 'catalog_1385656435', label: 'Catalog 3' },
		{ value: 'catalog_1384357', label: 'Catalog 4' },
		{ value: 'catalog_13845635', label: 'Catalog 5' },
		{ value: 'catalog_13843577', label: 'Catalog 6' },
		{ value: 'catalog_13843599', label: 'Catalog 7' },
	],
	paginator: {},
	status: 200,
};
