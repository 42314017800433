export const permissions = [
	{
		permissionId: 1,
		label: 'View Buyer',
		slug: 'view_buyer',
		permissionType: 'Product',
		isDependent: [],
		toggleValue: true,
		subPermission: {},
	},
	{
		permissionId: 2,
		label: 'Create Buyer',
		slug: 'create_buyer',
		permissionType: 'Buyer Module',
		isDependent: [],
		toggleValue: true,
		subPermission: {},
	},
	{
		permissionId: 3,
		label: 'Edit Buyer',
		slug: 'edit_buyer',
		permissionType: 'Buyer Module',
		isDependent: [],
		toggleValue: true,
		subPermission: {},
	},
	{
		permissionId: 4,
		label: 'View Buyer Group',
		slug: 'view_buyer_group',
		permissionType: 'Buyer Module',
		isDependent: [],
		toggleValue: true,
		subPermission: {},
	},
	{
		permissionId: 5,
		label: 'Create Buyer',
		slug: 'create_buyer_group',
		permissionType: 'Buyer Module',
		isDependent: [],
		toggleValue: true,
		subPermission: {},
	},
	{
		permissionId: 6,
		label: 'Edit Buyer',
		slug: 'edit_buyer_group',
		permissionType: 'Buyer Module',
		isDependent: [],
		toggleValue: true,
		subPermission: {},
	},
	{
		permissionId: 7,
		label: 'View All Products',
		slug: 'view_all_products',
		permissionType: 'PIM',
		isDependent: [],
		toggleValue: true,
		subPermission: {},
	},
	{
		permissionId: 8,
		label: 'User Management',
		slug: 'view_user_management',
		permissionType: 'user',
		isDependent: [],
		toggleValue: true,
		subPermission: {},
	},
];
