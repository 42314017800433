import { ReactNode } from 'react';

import Grid from '../Grid';

export interface PageHeaderProps {
	leftSection?: ReactNode;
	rightSection?: ReactNode;
	shiftToNextLine?: ReactNode;
	style?: any;
}

const PageHeader = ({ shiftToNextLine, leftSection, rightSection, style }: PageHeaderProps) => (
	<Grid
		container
		sx={{ ...style, zIndex: 150 }}
		alignItems='center'
		direction='row'
		justifyContent='space-between'
		className='breadcrumb-header'>
		{shiftToNextLine ? (
			<>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					{leftSection}
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					{rightSection}
				</Grid>
			</>
		) : (
			<>
				<Grid item xs={6}>
					{leftSection}
				</Grid>
				<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					{rightSection}
				</Grid>
			</>
		)}
	</Grid>
);

PageHeader.defaultProps = {
	style: {},
	shiftToNextLine: false,
	leftSection: <></>,
	rightSection: <></>,
};

export default PageHeader;
