import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update_catalog } from 'src/actions/buyer';
import { initializeCart } from 'src/actions/cart';
import cart_management from 'src/utils/api_requests/cartManagement';
import { get_customer_metadata } from 'src/utils/utils';

const useProductListingPageTemplate = () => {
	const [cart2] = useState({});
	const [cart_loading, set_cart_loading] = useState(false);
	const dispatch = useDispatch();
	const buyer = useSelector((state: any) => state.buyer);

	const initialize_cart = async () => {
		set_cart_loading(true);
		const { buyer_cart, is_guest_buyer } = buyer;

		const cart_id = buyer_cart?.id;

		if (!cart_id) return;

		try {
			const response: any = await cart_management.get_cart_details({ cart_id, is_guest_buyer });

			if (response.status === 200) {
				const { cart } = response;
				const { items, products: _product } = cart;

				if (cart?.catalog_ids?.length > 0 && cart?.catalog_ids?.[0] !== '' && cart?.catalog_ids?.[0] !== buyer?.catalog?.value) {
					dispatch<any>(update_catalog({ catalog: { value: cart?.catalog_ids[0], label: '' } }));
				}

				if (items && Object.keys(items)?.length > 0) {
					for (let _item in items) {
						const { id, parent_id } = _product[_item];

						items[_item].parent_id = parent_id;
						items[_item].id = id;
					}
				}
				dispatch(
					initializeCart({
						id: cart_id,
						products: items,
						products_details: _product,
						document_items: cart?.document_items || {},
						container_data: cart?.container_data || {},
						meta: cart?.meta || {},
					}),
				);
				set_cart_loading(false);
			}
		} catch (error: any) {
			console.error(error);
		}
	};
	// const handle_get_configuration = async () => {
	// 	try {
	// 		const response: any = await product_listing.get_listing_configuration();
	// 		set_cart(response.data);
	// 	} catch (e) {
	// 		console.log('get cart error');
	// 	} finally {
	// 		set_cart_loading(false);
	// 	}
	// };

	// useEffect(() => {
	// 	handle_get_configuration();
	// }, []);
	const customer_metadata = get_customer_metadata({ is_loggedin: true });
	return {
		cart: { data: cart2, loading: cart_loading },
		initialize_cart,
		customer_metadata,
	};
};

export default useProductListingPageTemplate;
