export const buyer_permission_data = {
	attributes: {
		company_name: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		display_name: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		sales_reps: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		catalog_group: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		is_taxable: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		tax_rate: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		payment_mode: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		shipping_terms: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		payment_terms: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		freight_terms: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
		shipping_method: {
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
		},
	},
	section: {
		contacts: {
			users_with_add_permissions: 'all',
			roles_with_add_permissions: 'all',
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
			users_with_delete_permissions: 'all',
			roles_with_delete_permissions: 'all',
			max_number_of_entities: 10000,
		},
		shipping_addresses: {
			users_with_add_permissions: 'all',
			roles_with_add_permissions: 'all',
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
			users_with_delete_permissions: 'all',
			roles_with_delete_permissions: 'all',
			max_number_of_entities: 10000,
		},
		billing_addresses: {
			users_with_add_permissions: 'all',
			roles_with_add_permissions: 'all',
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
			users_with_delete_permissions: 'all',
			roles_with_delete_permissions: 'all',
			max_number_of_entities: 10000,
		},
		payment_methods: {
			users_with_add_permissions: 'all',
			roles_with_add_permissions: 'all',
			users_with_edit_permissions: 'all',
			roles_with_edit_permissions: 'all',
			users_with_delete_permissions: 'all',
			roles_with_delete_permissions: 'all',
			max_number_of_entities: 10000,
		},
	},
};
