/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import classes from '../components/Product.module.css';
import { useSelector } from 'react-redux';
import { Button, Grid, Icon, Image, Counter, Tooltip, Chip, Box } from 'src/common/@the-source/atoms';
import VariantDrawer from 'src/common/@the-source/molecules/VariantDrawer';
import { Product } from '../mock/ProductInterface';
import SimilarDrawer from 'src/common/@the-source/molecules/SimilarDrawer';
import { all_product_card_template } from '../mock/card_template';
import { Link, useNavigate } from 'react-router-dom';
import HasSimilar from 'src/common/@the-source/molecules/RecommendCard/HasSimilar';
import RouteNames from 'src/utils/RouteNames';
import { useTranslation } from 'react-i18next';
import { from_max_quantity, get_cart_items, get_max_quantity, get_product_detail } from '../utils';
import types from 'src/utils/types';
import _ from 'lodash';
import get_product_image from 'src/utils/ImageConstants';
import InventoryStatus from 'src/common/@the-source/molecules/Inventory/InventoryStatus';
import { INVENTORY_STATUS } from 'src/common/@the-source/molecules/Inventory/constants';
import dayjs from 'dayjs';
import utils, { get_attributes_mapping, get_product_id, get_product_metadata, should_handle_click } from 'src/utils/utils';
import CartDrawer from 'src/common/@the-source/molecules/CartItemDrawer/CartDrawer';
import React from 'react';
import CustomText from 'src/common/@the-source/CustomText';
import { secondary, success } from 'src/utils/light.theme';
import useIsCustomization from 'src/hooks/useIsCustomization';
import CommonCustomizationComponent from 'src/common/CommonCustomizationComp';
import { Mixpanel } from 'src/mixpanel';
import useProductListingPageTemplate from '../useProductListingPageTemplate';
import constants from 'src/utils/constants';
import { ISelectedFilters } from 'src/common/@the-source/molecules/FiltersAndChips/interfaces';
import Events from 'src/utils/events_constants';
import CatalogFactory from '../../../utils/catalog.utils';
import { PRODUCT_DETAILS_TYPE } from 'src/screens/ProductDetailsPage/constants';
import { remove_multiple_catalog_products, remove_single_catalog_product } from 'src/actions/catalog_mode';
import { useDispatch } from 'react-redux';
import ImageLinks from 'src/assets/images/ImageLinks';
import { makeStyles } from '@mui/styles';
import { colors } from 'src/utils/theme';
import { useCatalogSelection } from 'src/hooks/useCatalogSelection';

const useStyles = makeStyles(() => ({
	review_icon_style: {
		backgroundColor: colors.white,
		borderRadius: '2.1px',
	},
	selected_close_icon: {
		position: 'absolute',
		right: '8px',
		top: '8px',
		zIndex: 99,
		backgroundColor: colors.white,
		borderRadius: '100px',
		padding: '4px',
		boxShadow: '0px 4.88px 14.64px 0px #00000014',
		cursor: 'pointer',
	},
}));

interface ProductTemplateTwoProps {
	type?: 'REVIEW' | 'ACTION' | 'SELECTED';
	product: Product;
	cards_template: any;
	has_similar: boolean;
	is_previous?: boolean;
	container_style?: React.CSSProperties;
	section_name?: any;
	page_name?: any;
	catalog_mode?: boolean;
	on_handle_cancel?: (id: string) => void;
	allow_pdp_navigation?: boolean;
}

const ProductTemplateTwo = ({
	type = constants.PRODUCT_CARD_TYPE.ACTION,
	product,
	cards_template,
	has_similar,
	container_style,
	is_previous = false,
	section_name,
	page_name,
	catalog_mode = false,
	on_handle_cancel = () => {},
	allow_pdp_navigation = true,
}: ProductTemplateTwoProps) => {
	const { catalog_products } = useSelector((state: any) => state?.catalog_mode);
	const cart = useSelector((state: any) => state?.cart);
	const { product_card_config = [], all_cards_config = {} } = useSelector((state: any) => state?.settings);
	const [drawer, setDrawer] = useState(false);
	const [drawerV, setDrawerV] = useState(false);
	const [cart_drawer, set_cart_drawer] = useState(false);
	const product_refs: any = useRef({}).current;
	// const [show_ellipsis, set_show_ellipsis] = useState<boolean>(false);
	const data_values = get_product_detail(product);
	const get_product_rows = _.get(all_cards_config, 'product.rows');
	const [price, set_price] = useState(product?.pricing?.price);
	const [show_customization_drawer, set_show_customization_drawer] = useState<boolean>(false);
	const [selected_filters, set_selected_filters] = useState<ISelectedFilters>({ filters: {}, range_filters: {} });
	const [select_partial, set_select_partial] = useState(false);
	const assignRef = (node: any, product_id: any) => {
		if (node) {
			product_refs[product_id] = node;
		}
	};
	const { is_customization_required, customize_id, grouping_identifier, get_and_initialize_cart } = useIsCustomization(product);
	const { selected, set_selected, handle_select_variant } = useCatalogSelection();

	const count_non_custom_product = (cart: any, data_values: any) => {
		return _.reduce(
			_.values(cart?.products),
			(acc, product) => {
				const isCustom = _.some(_.values(product), (item) => item?.is_custom_product);
				return !isCustom && data_values?.parent_id === product?.parent_id ? acc + 1 : acc;
			},
			0,
		);
	};
	const count_selected_variants = (products: any[], variants: any[]) => {
		return _.reduce(
			variants,
			(acc, variant) => {
				return products.includes(variant) ? acc + 1 : acc;
			},
			0,
		);
	};
	const { customer_metadata } = useProductListingPageTemplate();
	const product_metadata = get_product_metadata(product);
	const filtered_keys: any = utils.get_non_discount_keys(product, cart?.products, data_values?.product_id);
	const discounted_products: any = utils.get_discount_detail(cart.products?.[product?.id]);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const icon_classes = useStyles();

	const variants_count = _.filter(product?.variants_meta?.variant_data_map, (e: any) => e.is_active !== false)?.length;
	const is_review_active = type === constants.PRODUCT_CARD_TYPE.REVIEW;
	const is_selected_active = type === constants.PRODUCT_CARD_TYPE.SELECTED;
	const variant_id = get_product_id(product);
	const variant_ids: string[] = _.map(_.get(product, 'variants_meta.variant_data_map', []), (item: any) => item.product_id);
	const selected_variants = catalog_mode
		? count_selected_variants(catalog_products, variant_ids)
		: count_non_custom_product(cart, data_values);

	// const check_overflow = (product_id: string) => {
	// 	const ref = product_refs[product_id];
	// 	if (ref) {
	// 		const is_overflowing = ref.offsetWidth > 180;
	// 		set_show_ellipsis(is_overflowing);
	// 	}
	// };

	const custom_variant_template = get_attributes_mapping(product_card_config, product);

	const handleVariant = () => {
		setDrawerV(true);
	};
	const handleDiscountProduct = () => {
		set_cart_drawer(true);
	};

	const similarSW = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
		Mixpanel.track(Events.VIEW_SIMILAR_CLICKED, {
			tab_name: 'Products',
			page_name,
			section_name,
			subtab_name: '',
			customer_metadata,
			product_metadata,
		});
		setDrawer(true);
		e.stopPropagation();
		e?.preventDefault(); // Fix to prevent navigation of Link component
	};
	const formatDate = (dateString: string) => {
		try {
			const date = dayjs(dateString);
			if (!date?.isValid()) {
				throw new Error('Invalid date string');
			}
			const formattedDate = date?.format(constants.ATTRIBUTE_DATE_FORMAT);
			return formattedDate;
		} catch (error) {
			console.error('Error formatting date:', error);
			return '';
		}
	};
	const get_variant_details = () => {
		if (!data_values?.is_variant && variants_count > 1) {
			return (
				<Grid className={classes.variant_badge}>
					<Grid className={selected_variants > 0 ? classes.selected_variant_value : classes.variant_value}>
						{selected_variants > 0 ? `${selected_variants}/${variants_count}` : `+ ${variants_count}`}
					</Grid>
				</Grid>
			);
		}
		return <></>;
	};
	const render_column_content = (column: any, index: number) => {
		const styles = column?.style;
		switch (column?.key) {
			case 'name':
				return (
					<Grid className={classes.detail_container} key={column?.key}>
						<CustomText className={classes.product_name} style={styles}>
							{utils.get_column_display_value(column, product, price, data_values)}
						</CustomText>

						{index === 0 && get_variant_details()}
					</Grid>
				);
			case 'sku_id':
				return (
					<Grid sx={styles} className={classes.text_overflow} key={column?.key}>
						{utils.get_column_display_value(column, product, price, data_values)}
					</Grid>
				);
			default:
				if (column?.type === 'price') {
					const key = data_values?.is_variant ? column?.variant_key : column?.product_key;
					return (
						<Grid sx={styles} className={classes.text_overflow} key={key}>
							{utils.get_column_display_value(column, product, price, data_values)}
						</Grid>
					);
				} else
					return (
						<Grid sx={styles} key={column?.key}>
							{utils.get_column_display_value(column, product, price, data_values)}
						</Grid>
					);
		}
	};

	const reserved = cart?.document_items?.[product.id]?.total_reserved ?? 0;
	const total_available = product?.inventory?.total_available ?? 0;
	const out_of_stock_threshold = product?.inventory?.out_of_stock_threshold ?? 0;
	const is_not_in_stock = reserved + total_available <= out_of_stock_threshold;
	const max_quantity = get_max_quantity(product, reserved);
	const disable_counter = product?.inventory?.inventory_status === INVENTORY_STATUS.out_of_stock && is_not_in_stock && variants_count <= 1;

	// useLayoutEffect(() => {
	// 	check_overflow(product?.id);
	// }, [product?.id]);

	const handle_mark_for_review = (e: any) => {
		e.preventDefault();
		if (variants_count > 1 && product?.type === PRODUCT_DETAILS_TYPE.product) {
			setDrawerV(true);
			return;
		}
		if (select_partial) {
			dispatch(remove_multiple_catalog_products(variant_ids));
			set_select_partial(false);
			return;
		}
		handle_select_variant(variant_id);
	};

	const handle_product_card_click = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!should_handle_click(e) || !allow_pdp_navigation) return;
		if (is_review_active) {
			handle_mark_for_review(e);
			return;
		}
		navigate(`${RouteNames.product.product_detail.routing_path}${product?.id}`);
		window.scrollTo(0, 0);

		Mixpanel.track(Events.PRODUCT_CARD_CLICKED, {
			tab_name: 'Products',
			page_name,
			section_name,
			subtab_name: '',
			customer_metadata,
			product_metadata,
		});
	};

	const handle_product_chips = (e: any) => {
		if (is_selected_active) return;
		if (is_review_active) {
			handle_mark_for_review(e);
		} else {
			navigate(`${RouteNames.product.product_detail.routing_path}${product?.id}`);
		}
	};

	const handle_remove = () => {
		dispatch(remove_single_catalog_product(variant_id));
		on_handle_cancel(variant_id);
	};

	const handle_customization = (event: React.MouseEvent<HTMLButtonElement>) => {
		event?.stopPropagation();
		set_show_customization_drawer(true);
	};

	const handle_navigate_to_pdp = () => {
		if (!allow_pdp_navigation) return;
		navigate(`${RouteNames.product.product_detail.routing_path}${product?.id}`);
	};

	useEffect(() => {
		if (!catalog_mode && !is_review_active) return;
		const check = CatalogFactory.PRODUCT.check_multiple_products(variant_ids, catalog_products);
		const check_for_variant = CatalogFactory.PRODUCT.check_product(variant_id);
		const is_variant = product?.type === PRODUCT_DETAILS_TYPE.variant;
		const mark_for_review = (check.is_complete && !is_variant) || ((is_variant || variants_count === 0) && check_for_variant);
		set_selected(mark_for_review);
		if (is_variant) return;
		set_select_partial(!check.is_complete && check.is_partial);
	}, [catalog_mode, catalog_products]);

	return (
		<Grid container className={classes.product_container} id={product?.id}>
			{is_review_active && (
				<Box onClick={handle_mark_for_review} className={classes.review_checkbox}>
					{!selected && !select_partial && (
						<Image
							width={'16px'}
							height={'16px'}
							imgClass={icon_classes.review_icon_style}
							src={ImageLinks.checkbox_unchecked}
							alt='Unchecked'
						/>
					)}
					{selected && !select_partial && (
						<Image
							width={'16px'}
							height={'16px'}
							imgClass={icon_classes.review_icon_style}
							src={ImageLinks.checkbox_checked}
							alt='Checked'
						/>
					)}
					{select_partial && (
						<Image
							width={'16px'}
							height={'16px'}
							imgClass={icon_classes.review_icon_style}
							src={ImageLinks.checkbox_intermediate}
							alt='Intermediate'
						/>
					)}
				</Box>
			)}
			{is_selected_active && (
				<Icon iconName='IconX' color={secondary[700]} onClick={handle_remove} className={`${icon_classes.selected_close_icon}`} />
			)}

			<Link
				to={`${RouteNames.product.product_detail.routing_path}${product?.id}`}
				onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => utils.prevent_default_link_click(event)}
				style={{ width: '100%' }}>
				<Grid className={classes.image_container} container onClick={handle_product_card_click}>
					<Image src={get_product_image(product, 'PRODUCT_CARD')} imgClass={classes.product_image} />
					{is_previous && (
						<Chip
							bgColor={success[50]}
							textColor={colors.grey_800}
							className={classes.date_chip}
							label={`Last order: ${formatDate(product?.updated_at)}`}
						/>
					)}
					{has_similar && <HasSimilar similarDrawer={similarSW} />}
					<InventoryStatus
						data={product}
						variantType='chip'
						style={
							is_previous && {
								bottom: 10,
								left: 5,
								top: null,
							}
						}
					/>
				</Grid>
			</Link>

			<Grid
				container
				sx={{
					paddingBottom: is_selected_active ? '12px' : '',
				}}>
				{_.flatMap(get_product_rows || cards_template?.rows, (row) => row).map((column: any, index: number) => (
					<Grid
						display='flex'
						flexDirection='column'
						key={`row_${index}`}
						className={classes.product_detail}
						container
						// Don't let navigate to pdp in catalog mode
						onClick={catalog_mode ? handle_product_card_click : handle_navigate_to_pdp}>
						<Grid display={'flex'} sx={{ maxWidth: '100%' }}>
							{render_column_content(column, index)}
						</Grid>
					</Grid>
				))}
				{custom_variant_template?.map((row: any, _index: number) => (
					<div style={{ width: '100%' }} className={classes.hinge_product_detail} key={`attribute_row_${_index}`}>
						<div
							className={_index === 0 ? classes.product_attribute_detail : classes.product_attribute_detail_nth}
							ref={(el) => assignRef(el, product?.id)}>
							{row?.attributes?.keys?.map((key: any) => {
								const columnValue = utils.get_column_display_value(key, product, price, data_values);
								if (columnValue) {
									return (
										<Tooltip title={columnValue} placement='top' onClose={() => {}} onOpen={() => {}}>
											<div className={classes.hinge_attr_value} onClick={handle_product_chips} key={key}>
												{/* {index > 0 && <Icon iconName='IconPointFilled' className={classes.list_circle} />} */}
												<CustomText className={classes.hinge_custom_text} type='Caption' color={secondary[700]}>
													{columnValue}
												</CustomText>
											</div>
										</Tooltip>
									);
								}

								return null;
							})}
						</div>
						{/* {show_ellipsis && <p className={classes.ellipsis}>{'...'}</p>} */}
					</div>
				))}
				{!is_selected_active && (
					<Grid container justifyContent='center' alignItems='center' className={classes.add_to_button}>
						{is_review_active ? (
							<Button
								size='large'
								id={`view_details_${product?.id}`}
								tonal
								className={classes.cart_button}
								onClick={handle_navigate_to_pdp}
								sx={{
									height: '40px',
									boxShadow: 'none',
								}}>
								<Grid>{t('ProductList.Main.ViewDetails')}</Grid>
							</Button>
						) : data_values?.is_variant || variants_count === 1 ? (
							<>
								{!discounted_products?.is_discount_applied ? (
									<Counter
										disabled={disable_counter}
										step={product?.pricing?.step_increment || types.STEP_INCREMENT}
										initialCount={0}
										cart_item_key={filtered_keys}
										min={product?.pricing?.min_order_quantity || types.MIN_ORDER_QUANTITY}
										max={max_quantity}
										from_max={from_max_quantity(max_quantity, product?.pricing?.max_order_quantity ?? types.DEFAULT_ORDER_QUANTITY)}
										product_id={data_values?.product_id}
										product={product}
										parent_id={data_values?.parent_id}
										containerStyle={{
											display: 'flex', // Use flex display
											justifyContent: 'space-between',
											...container_style,
										}}
										default_order_quantity={product?.pricing?.default_order_quantity}
										volume_tiers={product?.pricing?.volume_tiers}
										set_price={set_price}
										is_customization_required={is_customization_required}
										handle_customization={handle_customization}
										page_name={page_name}
										section_name={section_name}
									/>
								) : (
									<>
										<Grid
											id={`counter_${product?.id}`}
											container
											style={{
												whiteSpace: 'nowrap',
												display: 'flex', // Use flex display
												justifyContent: 'space-between',
											}}
											alignItems='center'
											wrap='nowrap'>
											<Icon
												color='primary'
												iconName='IconMinus'
												fontSize='small'
												onClick={handleDiscountProduct}
												className={classes.remove_icon}
											/>
											<Grid
												item
												alignItems='center'
												xl={8}
												lg={8}
												md={8}
												sm={8}
												xs={8}
												style={{
													flex: '1',
												}}>
												<input
													readOnly={true}
													className={classes.show_count}
													type='number'
													value={utils.get_cart_items(data_values?.product_id, cart) as any}
													onClick={handleDiscountProduct}
												/>
											</Grid>
											<Icon
												color={'primary'}
												iconName='IconPlus'
												fontSize='small'
												className={classes.add_icon}
												onClick={handleDiscountProduct}
											/>
										</Grid>
									</>
								)}
							</>
						) : (
							<>
								{selected_variants > 0 ? (
									<Grid
										container
										id={`counter_${product?.id}`}
										style={{
											whiteSpace: 'nowrap',
											display: 'flex', // Use flex display
											justifyContent: 'space-between',
										}}
										alignItems='center'
										wrap='nowrap'>
										<Icon color='primary' iconName='IconMinus' fontSize='small' onClick={handleVariant} className={classes.remove_icon} />
										<Grid
											item
											alignItems='center'
											xl={8}
											lg={8}
											md={8}
											sm={8}
											xs={8}
											style={{
												flex: '1', // Let this item grow as needed
											}}>
											<input
												readOnly={true}
												className={classes.show_count}
												type='number'
												value={get_cart_items(product, cart)}
												onClick={handleVariant}
											/>
										</Grid>
										<Icon color={'primary'} iconName='IconPlus' fontSize='small' className={classes.add_icon} onClick={handleVariant} />
									</Grid>
								) : (
									<Button
										size='large'
										id={`add_to_cart_${product?.id}`}
										tonal
										className={classes.cart_button}
										onClick={handleVariant}
										disabled={disable_counter}
										sx={{
											height: '40px',
											boxShadow: 'none',
											color: disable_counter ? '#A2CFBF' : '',
											background: disable_counter ? '#F6FAF9' : '',
											'&:hover': { background: disable_counter ? '#F6FAF9' : '', color: disable_counter ? '#A2CFBF' : '' },
										}}>
										<Grid>{t('ProductList.Main.AddToCart')}</Grid>
									</Button>
								)}
							</>
						)}
					</Grid>
				)}
				{drawerV && (
					<VariantDrawer
						drawer={drawerV}
						set_drawer={setDrawerV}
						id={product?.id}
						attribute_template={custom_variant_template}
						parent_product={product}
						set_selected_filters={set_selected_filters}
						selected_filters={selected_filters}
					/>
				)}
				{drawer && <SimilarDrawer drawer={drawer} setDrawer={setDrawer} simillar={product?.id} card_temp={all_product_card_template} />}

				{cart_drawer && (
					<CartDrawer
						show={cart_drawer}
						set_show={set_cart_drawer}
						data={product}
						cart_product_id={product?.id}
						attribute_template={custom_variant_template}
					/>
				)}
			</Grid>
			{show_customization_drawer && (
				<CommonCustomizationComponent
					customize_id={customize_id}
					product_details={product}
					grouping_identifier={grouping_identifier}
					get_and_initialize_cart={get_and_initialize_cart}
					show_customization_drawer={show_customization_drawer}
					set_show_customization_drawer={set_show_customization_drawer}
				/>
			)}
		</Grid>
	);
};

export default ProductTemplateTwo;
