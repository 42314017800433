export const col_response = {
	status_code: 200,
	content: {
		message: 'success',
		data: [
			{
				id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				name: 'Living Room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 1,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				children: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/28ebc5f5-3934-42bd-bfcc-d67da257906d',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				name: 'Drawing Room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 2,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96,05e419d7-7cf3-43f5-9301-dfba838911e7',
				children: '6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/1b2529d9-aa83-4c00-a99c-0897c9e05c67',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				name: 'category 3',
				parent_id: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				level: 3,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96,05e419d7-7cf3-43f5-9301-dfba838911e7,6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				children: '',
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/68ba1761-1b49-4ed2-8e95-5ef4931c7a5c',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				name: 'category 4',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 1,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				children: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/28ebc5f5-3934-42bd-bfcc-d67da257906d',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				name: 'Living Room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 1,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				children: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/28ebc5f5-3934-42bd-bfcc-d67da257906d',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				name: 'Drawing Room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 2,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96,05e419d7-7cf3-43f5-9301-dfba838911e7',
				children: '6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/1b2529d9-aa83-4c00-a99c-0897c9e05c67',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				name: 'category 3',
				parent_id: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				level: 3,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96,05e419d7-7cf3-43f5-9301-dfba838911e7,6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				children: '',
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/68ba1761-1b49-4ed2-8e95-5ef4931c7a5c',
						type: 'image',
						is_primary: true,
					},
				],
			},
		],
	},
};

export const pdp_response = {
	status: 200,
	data: {
		sku_id: 'SS-253-2026',
		type: 'product',
		media: [
			{
				id: '1',
				url: 'https://sourcerer.tech/assets/cbf7400c-f30b-4de3-819b-7db00b74bf25',
				type: 'image',
				is_primary: true,
			},
			{
				id: '2',
				url: 'https://sourcerer.tech/assets/ef05b74e-1b4f-4434-8999-2c1e90a14710',
				type: 'image',
				is_primary: false,
			},
		],
		tenant_id: '0f349df7-7c9b-4b95-b370-e889b63032f2',
		status: 'published',
		parent_id: 'a083b647-3ae4-4283-a7fc-3c2ff5d22e37',
		moq: 5,
		name: 'Chloe Coverlet Sham',
		variants_meta: {
			hinges_value_map: {
				'014c1ba0-c885-4cdc-b589-62d68f3f66c8': [
					{
						value: 'White',
						priority: 0,
						type: 'image_label',
						type_value: 'https://sourcerer.tech/assets/cbf7400c-f30b-4de3-819b-7db00b74bf25',
					},
				],
				'f52bedd2-a7f1-46bf-acd4-addcd69ffac9': [
					{
						value: 'Standard',
						priority: 0,
						type: 'chip',
						type_value: '',
					},
				],
			},
			variant_data_map: [
				{
					'014c1ba0-c885-4cdc-b589-62d68f3f66c8': 'White',
					'f52bedd2-a7f1-46bf-acd4-addcd69ffac9': 'Standard',
					product_id: 'a083b647-3ae4-4283-a7fc-3c2ff5d22e37',
				},
			],
			hinge_attributes: [
				{
					id: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
					name: 'color',
					priority: 0,
				},
				{
					id: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
					name: 'size',
					priority: 0,
				},
			],
		},
		custom_attributes: [
			{
				id: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
				name: 'color',
				type: 'select',
				value: 'White',
			},
			{
				id: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
				name: 'size',
				type: 'select',
				value: 'Standard',
			},
			{
				id: '8c170d99-f045-483f-bcdb-d3d8ae3cc00d',
				name: 'other_information',
				type: 'textarea',
				value:
					'WASHING: Wash separately in cold water on a short, gentle cycle, with as much water as possible to allow for movement.\nDRYING: Careful drying is really important. Tumble dry on a delicate setting, low heat. ',
			},
			{
				id: '920acaa3-1c2b-465c-84c3-4b31dc62402e',
				name: 'description',
				type: 'textarea',
				value:
					'Our Chloe coverlet and shams are made from 100% European Linen in the front and backed by 100% cotton voile. They feature quilting and padding in a geometric pattern, adding comfort and coziness to your bed, and are designed in a simple elegant way. It’s a perfect combination of form and functionality created for the mind, body, and soul.',
			},
			{
				id: 'cdcc3756-0cf9-4004-ba62-1856ae8dd7ca',
				name: 'weight',
				type: 'text',
				value: '1.25 lbs',
			},
			{
				id: 'fc420b17-3d1b-4f68-b218-354567e45887',
				name: 'dimensions',
				type: 'text',
				value: '20X36 inch',
			},
		],
		updated_at: '2023-07-26T19:19:58.393726',
		created_at: '2023-07-26T19:18:12.390430',
		id: 'a083b647-3ae4-4283-a7fc-3c2ff5d22e37',
		pricing: {
			variant_price_range: {
				min_value: 220,
				max_value: 300,
			},
			unit: 'per piece',
			price: 150,
			pricing_rule: {
				volume: {
					name: 'Volume Discount',
					type: 'Volume',
					tiers: [
						{
							min_qty: 1,
							max_qty: 30,
							type: '',
							value: '',
							final_price: 300,
						},
						{
							min_qty: 31,
							max_qty: 100,
							type: '',
							value: '',
							final_price: 280,
						},
						{
							min_qty: 101,
							max_qty: 150,
							type: '',
							value: '',
							final_price: 250,
						},
						{
							min_qty: 151,
							max_qty: -1,
							type: '',
							value: '',
							final_price: 220,
						},
					],
				},
			},
			product_id: 'a083b647-3ae4-4283-a7fc-3c2ff5d22e37',
			price_level_id: '01d11338-4674-4643-a013-f18c5e579d93',
			id: 'be236f18-63ca-4e5e-a26b-4b3b8fe9ca59',
			currency: '$',
		},
		inventory: {
			min_order_quantity: 5,
			incremental_value: 1,
			max_order_quantity: 100,
			stock: 200,
			name: 'In Stock : 200.0 pcs',
			box_color: 'primary',
			show_stock_list: 'true',
			list: {
				in_stock: '200.0 pcs',
				on_hold: '',
				on_loom: '',
			},
		},
		category: [
			{
				name: 'Quilt',
				parent_id: '9782b017-7483-44ee-8417-84ad3cc97926',
				id: 'fb02b773-f72a-47af-ab77-8f7884c964b0',
				children: null,
				level: 2,
				path: '9782b017-7483-44ee-8417-84ad3cc97926,9782b017-7483-44ee-8417-84ad3cc97926',
			},
		],
		collections: [
			{
				media: {},
				id: 'ce251251-cc48-4aef-84ad-94705a670809',
				priority: 0,
				name: 'New arrivals',
			},
		],
	},
	content: {
		message: 'success',
	},
};

export const pdp_config_response = {
	status: 200,
	data: {
		sections: [
			{
				key: 'basic_details',
				name: 'Basic Details',
				type: 'open_section',
				priority: 1,
				attributes: [
					{
						attribute_id: '920acaa3-1c2b-465c-84c3-4b31dc62402e',
						priority: 1,
					},
				],
			},
			{
				key: 'shipping_details',
				name: 'SHIPPING DETAILS',
				type: 'collapsible',
				priority: 2,
				attributes: [
					{
						attribute_id: '8c170d99-f045-483f-bcdb-d3d8ae3cc00d',
						priority: 1,
					},
					{
						attribute_id: '014c1ba0-c885-4cdc-b589-62d68f3f66c8',
						priority: 2,
					},
					{
						attribute_id: 'f52bedd2-a7f1-46bf-acd4-addcd69ffac9',
						priority: 3,
					},
				],
			},
			{
				key: 'details',
				name: 'Details',
				type: 'collapsible',
				priority: 3,
				attributes: [
					{
						attribute_id: 'cdcc3756-0cf9-4004-ba62-1856ae8dd7ca',
						priority: 1,
					},
					{
						attribute_id: 'fc420b17-3d1b-4f68-b218-354567e45887',
						priority: 2,
					},
				],
			},
		],
		rails: [
			{
				name: 'Similar Products',
				priority: 1,
				type: 'similar_product',
			},
			{
				name: 'Frequently Bought Together',
				priority: 2,
				type: 'frequently_bought_together',
			},
		],
	},
};

export const cat_response = {
	status_code: 200,
	content: {
		message: 'success',
		data: [
			{
				id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				name: 'Drawing room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 1,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				children: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				product_count: 234,
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/4bdbd569-6387-4dc8-8959-4cf6aebc0bf7',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				name: 'Living Room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 2,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96,05e419d7-7cf3-43f5-9301-dfba838911e7',
				children: '6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				product_count: 234,
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/ee5bbf4e-6b0f-4d9b-ae0e-b997d8053ca3',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				name: 'Waiting room',
				parent_id: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				level: 3,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96,05e419d7-7cf3-43f5-9301-dfba838911e7,6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				children: '',
				product_count: 234,
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/237a35f7-f84f-4f70-9e9b-cb0373aa5f0e',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				name: 'Study Room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 1,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				children: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				product_count: 234,
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/ee5bbf4e-6b0f-4d9b-ae0e-b997d8053ca3',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				name: 'Drawing room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 1,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				children: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				product_count: 234,
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/4bdbd569-6387-4dc8-8959-4cf6aebc0bf7',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				name: 'Living Room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 2,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96,05e419d7-7cf3-43f5-9301-dfba838911e7',
				children: '6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				product_count: 234,
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/ee5bbf4e-6b0f-4d9b-ae0e-b997d8053ca3',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				name: 'Waiting room',
				parent_id: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				level: 3,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96,05e419d7-7cf3-43f5-9301-dfba838911e7,6fa1ce06-b8e2-4260-a4f5-e42914d9ee7f',
				children: '',
				product_count: 234,
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/eb86dffe-50c0-445d-8119-79c41eb337c3',
						type: 'image',
						is_primary: true,
					},
				],
			},
			{
				id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				name: 'Study Room',
				parent_id: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				level: 1,
				path: '1c21d88b-0a8f-4bd3-a5b3-2e3d5e1d7f96',
				children: '05e419d7-7cf3-43f5-9301-dfba838911e7',
				product_count: 234,
				media: [
					{
						id: '1',
						url: 'https://sourcerer.tech/assets/ee5bbf4e-6b0f-4d9b-ae0e-b997d8053ca3',
						type: 'image',
						is_primary: true,
					},
				],
			},
		],
	},
};

export const wiz_ai_data_mock = {
	data: [
		{
			buyer_id: 'f60ff0e3-9515-4967-a2e7-e21839d11855',
			company_name: 'kalpesh test 2',
			buyer_reference_id: 'ref-2',
			last_order_date: '2024-02-20T13:36:31.681324+00:00',
			q1_2024: 3767.7000000000003,
			q2_2024: 0.0,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 3767.7000000000003,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 3767.7000000000003,
			change_in_amount_q1: 3767.7000000000003,
			change_in_amount_q2: 0.0,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 3767.7000000000003,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'medium',
			insights: "['a', 'b', 'c']",
			buyer_activity_created_at: '2024-06-07T22:07:58.278907',
			buyer_activity_type: 'emailed',
			buyer_activity_note: 'This is latest update',
		},
		{
			buyer_id: '3083847a-cb54-466f-9d7f-45a11189b76b',
			company_name: 'wiebdiewdew',
			buyer_reference_id: '672366874',
			last_order_date: '2024-05-21T16:54:40.045654+00:00',
			q1_2024: 0.0,
			q2_2024: 3603.9,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 3603.9,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 3603.9,
			change_in_amount_q1: 0.0,
			change_in_amount_q2: 3603.9,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 0.0,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 3603.9,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'medium',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: 'b40c2464-40d5-4f7e-86b5-a9e97215c93a',
			company_name: 'check',
			buyer_reference_id: '596869402',
			last_order_date: '2024-02-07T13:09:40.663073+00:00',
			q1_2024: 26629.149999999998,
			q2_2024: 0.0,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 26629.149999999998,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 26629.149999999998,
			change_in_amount_q1: 26629.149999999998,
			change_in_amount_q2: 0.0,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 26629.149999999998,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'medium',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: '32b2c76c-9559-45ed-a857-90be790377d8',
			company_name: 'adsd',
			buyer_reference_id: '596410650',
			last_order_date: '2024-03-12T13:29:51.367350+00:00',
			q1_2024: 20.0,
			q2_2024: 0.0,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 20.0,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 20.0,
			change_in_amount_q1: 20.0,
			change_in_amount_q2: 0.0,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 0.0,
			change_in_amount_march: 20.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'high',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: '9691d8e1-1af2-445a-82ff-32cfdce3fe0b',
			company_name: 'asdsad',
			buyer_reference_id: '596377882',
			last_order_date: '2024-04-25T12:19:03.799294+00:00',
			q1_2024: 0.0,
			q2_2024: 2629.95,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 2629.95,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 2629.95,
			change_in_amount_q1: 0.0,
			change_in_amount_q2: 2629.95,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 0.0,
			change_in_amount_march: 0.0,
			change_in_amount_april: 2629.95,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'high',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: 'e258429b-f07c-4577-9a6e-1c4e02f9ced5',
			company_name: 'Abhinandan',
			buyer_reference_id: '595919130',
			last_order_date: '2024-02-22T11:39:32.349962+00:00',
			q1_2024: 12809.54,
			q2_2024: 0.0,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 12809.54,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 12809.54,
			change_in_amount_q1: 12809.54,
			change_in_amount_q2: 0.0,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 12809.54,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'medium',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: '84ac87f3-0e97-4cbd-8502-8f40ae3775fb',
			company_name: 'Correct Buyer',
			buyer_reference_id: '',
			last_order_date: '2024-02-05T13:31:01.119544+00:00',
			q1_2024: 184693.88999999996,
			q2_2024: 0.0,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 184693.88999999996,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 184693.88999999996,
			change_in_amount_q1: 184693.88999999996,
			change_in_amount_q2: 0.0,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 184693.88999999996,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'high',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: '6e84655d-9fdd-4cd1-b464-ebfd9a8badef',
			company_name: 'Mybytecode Updated',
			buyer_reference_id: '',
			last_order_date: '2024-05-27T17:09:59.253181+00:00',
			q1_2024: 0.0,
			q2_2024: 629.95,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 629.95,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 629.95,
			change_in_amount_q1: 0.0,
			change_in_amount_q2: 629.95,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 0.0,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 629.95,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'low',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: 'a05ca6be-86d2-47f1-b824-6ce197b3ac6e',
			company_name: 'Org Setting',
			buyer_reference_id: '',
			last_order_date: '2024-02-02T15:35:52.160242+00:00',
			q1_2024: 8519.8,
			q2_2024: 0.0,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 8519.8,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 8519.8,
			change_in_amount_q1: 8519.8,
			change_in_amount_q2: 0.0,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 8519.8,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'high',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: 'aa09f844-d409-4de8-ab1f-8252c6b6b9e9',
			company_name: 'Shoes On Loose 2',
			buyer_reference_id: '',
			last_order_date: '2024-02-02T19:07:04.813485+00:00',
			q1_2024: 629.95,
			q2_2024: 0.0,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 629.95,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 629.95,
			change_in_amount_q1: 629.95,
			change_in_amount_q2: 0.0,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 629.95,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'high',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: '15408ee8-11cd-40c2-894c-551b88944be5',
			company_name: 'Sourcewiz',
			buyer_reference_id: '',
			last_order_date: '2024-05-21T16:49:26.504112+00:00',
			q1_2024: 0.0,
			q2_2024: 2629.95,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 2629.95,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 2629.95,
			change_in_amount_q1: 0.0,
			change_in_amount_q2: 2629.95,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 0.0,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 2629.95,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'high',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: 'efa57aed-12b8-41d7-8ccf-6dde892388ad',
			company_name: 'abcd',
			buyer_reference_id: '',
			last_order_date: '2024-05-01T09:54:59.423212+00:00',
			q1_2024: 16159.3,
			q2_2024: 689.95,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 16849.25,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 16849.25,
			change_in_amount_q1: 16159.3,
			change_in_amount_q2: 689.95,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 8369.55,
			change_in_amount_march: 7789.749999999999,
			change_in_amount_april: 669.95,
			change_in_amount_may: 20.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'medium',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: '175277a8-47d6-4345-9724-50dfa553a0a2',
			company_name: 'prince inc',
			buyer_reference_id: '',
			last_order_date: '2024-04-29T12:41:15.352288+00:00',
			q1_2024: 0.0,
			q2_2024: 20.0,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 20.0,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 20.0,
			change_in_amount_q1: 0.0,
			change_in_amount_q2: 20.0,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 0.0,
			change_in_amount_march: 0.0,
			change_in_amount_april: 20.0,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'medium',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: 'f691c932-9b6b-4b2f-8678-1776849b045a',
			company_name: 'temp tester 1234',
			buyer_reference_id: '',
			last_order_date: '2024-02-06T12:59:08.992173+00:00',
			q1_2024: 14099.699999999999,
			q2_2024: 0.0,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 14099.699999999999,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 14099.699999999999,
			change_in_amount_q1: 14099.699999999999,
			change_in_amount_q2: 0.0,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 14099.699999999999,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 0.0,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'high',
			insights: "['a', 'b', 'c']",
		},
		{
			buyer_id: '679ba8e4-b50d-41da-9024-608abdaa645c',
			company_name: 'tushar testing',
			buyer_reference_id: '',
			last_order_date: '2024-05-31T13:03:10.645008+00:00',
			q1_2024: 0.0,
			q2_2024: 2366.95,
			q3_2024: 0.0,
			q4_2024: 0.0,
			q1_2023: 0.0,
			q2_2023: 0.0,
			q3_2023: 0.0,
			q4_2023: 0.0,
			q1_2022: 0.0,
			q2_2022: 0.0,
			q3_2022: 0.0,
			q4_2022: 0.0,
			q1_2021: 0.0,
			q2_2021: 0.0,
			q3_2021: 0.0,
			q4_2021: 0.0,
			q1_2020: 0.0,
			q2_2020: 0.0,
			q3_2020: 0.0,
			q4_2020: 0.0,
			january_2024: 0.0,
			february_2024: 0.0,
			march_2024: 0.0,
			april_2024: 0.0,
			may_2024: 0.0,
			june_2024: 0.0,
			july_2024: 0.0,
			august_2024: 0.0,
			september_2024: 0.0,
			october_2024: 0.0,
			november_2024: 0.0,
			december_2024: 0.0,
			january_2023: 0.0,
			february_2023: 0.0,
			march_2023: 0.0,
			april_2023: 0.0,
			may_2023: 0.0,
			june_2023: 0.0,
			july_2023: 0.0,
			august_2023: 0.0,
			september_2023: 0.0,
			october_2023: 0.0,
			november_2023: 0.0,
			december_2023: 0.0,
			january_2022: 0.0,
			february_2022: 0.0,
			march_2022: 0.0,
			april_2022: 0.0,
			may_2022: 0.0,
			june_2022: 0.0,
			july_2022: 0.0,
			august_2022: 0.0,
			september_2022: 0.0,
			october_2022: 0.0,
			november_2022: 0.0,
			december_2022: 0.0,
			january_2021: 0.0,
			february_2021: 0.0,
			march_2021: 0.0,
			april_2021: 0.0,
			may_2021: 0.0,
			june_2021: 0.0,
			july_2021: 0.0,
			august_2021: 0.0,
			september_2021: 0.0,
			october_2021: 0.0,
			november_2021: 0.0,
			december_2021: 0.0,
			january_2020: 0.0,
			february_2020: 0.0,
			march_2020: 0.0,
			april_2020: 0.0,
			may_2020: 0.0,
			june_2020: 0.0,
			july_2020: 0.0,
			august_2020: 0.0,
			september_2020: 0.0,
			october_2020: 0.0,
			november_2020: 0.0,
			december_2020: 0.0,
			year_2024: 2366.95,
			year_2023: 0.0,
			year_2022: 0.0,
			year_2021: 0.0,
			year_2020: 0.0,
			change_in_amount_yearly: 2366.95,
			change_in_amount_q1: 0.0,
			change_in_amount_q2: 2366.95,
			change_in_amount_q3: 0.0,
			change_in_amount_q4: 0.0,
			change_in_amount_january: 0.0,
			change_in_amount_february: 0.0,
			change_in_amount_march: 0.0,
			change_in_amount_april: 0.0,
			change_in_amount_may: 2366.95,
			change_in_amount_june: 0.0,
			change_in_amount_july: 0.0,
			change_in_amount_august: 0.0,
			change_in_amount_september: 0.0,
			change_in_amount_october: 0.0,
			change_in_amount_november: 0.0,
			change_in_amount_december: 0.0,
			buyer_priority: 'medium',
			insights: "['a', 'b', 'c']",
		},
	],
	facets: {
		buyer_activity_type: {
			buckets: [
				{
					key: 'called',
					doc_count: 1,
				},
				{
					key: 'emailed',
					doc_count: 9,
				},
				{
					key: 'in_person_visit',
					doc_count: 0,
				},
				{
					key: 'requirement',
					doc_count: 0,
				},
				{
					key: 'follow_up',
					doc_count: 0,
				},
			],
		},
		buyer_priority: {
			buckets: [
				{
					key: 'medium',
					doc_count: 10,
				},
				{
					key: 'high',
					doc_count: 19,
				},
				{
					key: 'low',
					doc_count: 2,
				},
			],
		},
	},
};
