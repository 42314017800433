import { Box, Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from '@mui/material';

type DrawerBaseProps = Pick<MuiDrawerProps, 'open' | 'onClose' | 'anchor' | 'title'>;

export interface DrawerProps extends DrawerBaseProps {
	open: boolean;
	onClose: any;
	anchor?: any;
	width?: number;
	content?: any;
	style?: any;
}

const Drawer = ({ open, style, onClose, anchor, width, content, ...rest }: DrawerProps) => (
	<MuiDrawer style={style} open={open} onClose={onClose} anchor={anchor} {...rest}>
		<Box width={width}>{content}</Box>
	</MuiDrawer>
);

Drawer.defaultProps = { anchor: 'right', width: 420, style: {} };

export default Drawer;
