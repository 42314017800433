import CustomCounter from '../../common/@the-source/molecules/CustomCounter';
import ChipComponent from './ModifierComponents/ChipComponent';
import RadioComponent from './ModifierComponents/RadioComponent';
import ImageComponent from './ModifierComponents/ImageComponent';
import DropDownComponent from './ModifierComponents/DropDownComponent';

interface ModifierFieldProps {
	dType: string;
	modifier: string;
	values?: any;
	handleValues: any;
	data: any;
	options: any;
	id: string;
	handleError: any;
	searchString: string;
	is_edit?: boolean;
	currency: string;
}

const prevent_overflow = {
	textOverflow: 'ellipsis',
	maxWidth: '100px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
};

const search_string_style = {
	background: '#F7F8FA',
	padding: '1rem',
	borderRadius: '8px',
	marginTop: '0.5rem',
};

const ModifierField = ({
	dType,
	modifier,
	values,
	data,
	handleValues,
	options,
	id,
	handleError,
	searchString,
	is_edit,
	currency,
}: ModifierFieldProps) => {
	const defaultValue = is_edit ? options : options || data?.value;

	switch (dType) {
		case 'Single Select':
			switch (modifier) {
				case 'Chip':
					return (
						<ChipComponent
							values={values}
							prevent_overflow={prevent_overflow}
							default_value={defaultValue}
							id={id}
							onChange={handleValues}
							is_mandatory={data?.mandatory}
							handleError={handleError}
							search_string_style={searchString ? search_string_style : {}}
							currency={currency}
						/>
					);

				case 'Radio Button':
					return (
						<RadioComponent
							values={values}
							prevent_overflow={prevent_overflow}
							id={id}
							default_value={defaultValue}
							onChange={handleValues}
							is_mandatory={data?.mandatory}
							handleError={handleError}
							search_string_style={searchString ? search_string_style : {}}
							currency={currency}
						/>
					);
				case 'Dropdown':
					return (
						<DropDownComponent
							values={values}
							options={defaultValue}
							label={data?.name}
							show_checkbox={data?.type === 'multi_select'}
							onChange={handleValues}
							is_mandatory={data?.mandatory}
							id={id}
							min_selection_quantity={data?.min_selection_quantity}
							max_selection_quantity={data?.max_selection_quantity}
							handleError={handleError}
							currency={currency}
						/>
					);
				case 'Swatch':
					return (
						<ImageComponent
							values={values}
							options={defaultValue}
							id={id}
							onChange={handleValues}
							is_mandatory={data?.mandatory}
							min_selection_quantity={data?.min_selection_quantity}
							max_selection_quantity={data?.max_selection_quantity}
							handleError={handleError}
							search_string_style={searchString ? search_string_style : {}}
							currency={currency}
						/>
					);
				default:
					return null;
			}
		case 'Multi Select':
			switch (modifier) {
				case 'Dropdown':
					return (
						<DropDownComponent
							values={values}
							options={defaultValue}
							label={data?.name}
							show_checkbox={data?.type === 'Multi Select'}
							onChange={handleValues}
							is_mandatory={data?.mandatory}
							id={id}
							min_selection_quantity={data?.min_selection_quantity}
							max_selection_quantity={data?.max_selection_quantity > 0 ? data?.max_selection_quantity : Infinity}
							handleError={handleError}
							currency={currency}
						/>
					);
				case 'Swatch':
					return (
						<ImageComponent
							values={values}
							options={defaultValue}
							show_checkbox={dType === 'Multi Select'}
							onChange={handleValues}
							id={id}
							is_mandatory={data?.mandatory}
							min_selection_quantity={data?.min_selection_quantity}
							max_selection_quantity={data?.max_selection_quantity > 0 ? data?.max_selection_quantity : Infinity}
							handleError={handleError}
							search_string_style={searchString ? search_string_style : {}}
							currency={currency}
						/>
					);
				default:
					return null;
			}

		case 'Counter':
			switch (modifier) {
				case 'Counter':
					return (
						<CustomCounter
							is_mandatory={data?.mandatory}
							min={data?.min_selection_quantity || 0}
							max={data?.max_selection_quantity > 0 ? data?.max_selection_quantity : Infinity}
							onChange={handleValues}
							id={id}
							label={data?.name}
							handleError={handleError}
							defaultValue={defaultValue}
						/>
					);
				default:
					return null;
			}
		default:
			return null;
	}
};

export default ModifierField;
