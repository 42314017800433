export const response = {
	message: 'Ok',
	status_code: 200,
	success: true,
	data: [
		{
			media: [
				{
					id: '1',
					url: 'https://sourcerer.tech/assets/2c461492-05cc-4110-b966-6473fe3e905d',
					type: 'image',
					is_primary: true,
				},
				{
					id: '2',
					url: 'https://orchidsluxhome.com/wp-content/uploads/2022/05/RIVIERA-BLANKET-WHITE-3.jpg',
					type: 'image',
					is_primary: false,
				},
				{
					id: '3',
					url: 'https://orchidsluxhome.com/wp-content/uploads/2022/05/RIVIERA-BLANKET-WHITE-2.jpg',
					type: 'image',
					is_primary: false,
				},
			],
			name: 'Riviera Blanket',
			moq: 5,
			id: 'ac1ba692-061b-49e9-92af-849941ad16a9',
			type: 'product',
			parent_id: 'e2ab32db-6c34-415e-8373-da14cb50fd20',
			created_at: '2023-07-20T13:02:15.612990',
			sku_id: 'BL-853-11098',
			updated_at: '2023-07-20T13:02:15.612990',
			variants_meta: null,
			order_history: {
				'81569f54-aa20-42df-b0c8-5e3c21505480': {
					id: '81569f54-aa20-42df-b0c8-5e3c21505480',
					name: 'value',
					value: '2400',
					type: 'select',
					composite: {},
				},
				'b2be24ab-09a6-4406-98b0-e5b4ec1475b3': {
					id: 'b2be24ab-09a6-4406-98b0-e5b4ec1475b3',
					name: 'Quantity',
					value: '20',
					type: 'boolean',
					composite: {},
				},
			},
			custom_attributes: {
				'f7a4b289-73dd-4568-9d0f-3c90f985d7b1': {
					id: 'f7a4b289-73dd-4568-9d0f-3c90f985d7b1',
					name: 'Description',
					value:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					type: 'textarea',
					composite: {},
				},
				'81569f54-aa20-42df-b0c8-5e3c21505480': {
					id: '81569f54-aa20-42df-b0c8-5e3c21505480',
					name: 'Color',
					value: 'Red',
					type: 'select',
					composite: {},
				},
				'b2be24ab-09a6-4406-98b0-e5b4ec1475b3': {
					id: 'b2be24ab-09a6-4406-98b0-e5b4ec1475b3',
					name: 'Customizable',
					value: 'true',
					type: 'boolean',
					composite: {},
				},
			},
			tenant_id: '0f349df7-7c9b-4b95-b370-e889b63032f2',
			status: 'published',
			pricing: {
				created_at: '2023-07-20T13:45:31.948042',
				id: '5510e47a-e32f-4d7b-88ea-14f777061fa2',
				updated_at: '2023-07-20T13:45:31.948042',
				product_id: 'ac1ba692-061b-49e9-92af-849941ad16a9',
				pricing_rule: {
					name: 'Volume Discount',
					type: 'Volume',
					tiers: [
						{
							min_qty: 1,
							max_qty: 30.0,
							type: '',
							value: '',
							final_price: 300,
						},
						{
							min_qty: 31,
							max_qty: 100.0,
							type: '',
							value: '',
							final_price: 280,
						},
						{
							min_qty: 101,
							max_qty: 150.0,
							type: '',
							value: '',
							final_price: 250,
						},
						{
							min_qty: 151,
							max_qty: '',
							type: '',
							value: '',
							final_price: 220,
						},
					],
				},
				price_level_id: '40b84efa-88f8-47a2-b879-f760c0afe496',
				unit_id: '7fa59421-f57e-48ad-ba01-2cd63f1267f9',
				tenant_id: '0f349df7-7c9b-4b95-b370-e889b63032f2',
				status: 'published',
				price: 330.0,
			},
			inventory: {
				meta: {},
				stock: 200.0,
				created_at: '2023-07-24T13:55:05.857426',
				id: '01a18587-d118-4ea9-9807-05086aaa7347',
				updated_at: '2023-07-24T13:55:05.857426',
				product_id: 'ac1ba692-061b-49e9-92af-849941ad16a9',
				in_stock: true,
				tenant_id: '0f349df7-7c9b-4b95-b370-e889b63032f2',
				status: 'published',
			},
			category: [
				{
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					id: '6fdfae1c-550b-4cfd-a578-c8a0b2cd74ec',
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					level: 2,
					name: 'Blanket',
					children: null,
				},
				{
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					id: '6fdfae1c-550b-4cfd-a578-c8a0b2cd74ec',
					children: null,
					level: 2,
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					name: 'Blanket',
				},
				{
					children: null,
					id: '6fdfae1c-550b-4cfd-a578-c8a0b2cd74ec',
					name: 'Blanket',
					level: 2,
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
				},
				{
					id: '6fdfae1c-550b-4cfd-a578-c8a0b2cd74ec',
					children: null,
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					level: 2,
					name: 'Blanket',
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
				},
				{
					name: 'Blanket',
					id: '6fdfae1c-550b-4cfd-a578-c8a0b2cd74ec',
					children: null,
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					level: 2,
				},
			],
			collections: null,
		},
		{
			media: [
				{
					id: '1',
					url: 'https://sourcerer.tech/assets/0cdea92f-faa6-44a2-86b8-4467b6d51e08',
					type: 'image',
					is_primary: true,
				},
				{
					id: '2',
					url: 'https://sourcerer.tech/assets/29178491-5aa0-48d9-aa10-99ee00b8e0fa',
					type: 'image',
					is_primary: false,
				},
			],
			name: 'Chloe Coverlet',
			moq: 5,
			id: '535e68d0-cdb4-406b-b7b3-ceae3633023c',
			type: 'product',
			parent_id: '2dfaac0d-e84a-44f8-ba29-6c8a6b37a903',
			created_at: '2023-07-20T13:02:15.612990',
			sku_id: 'CL-253-11096',
			updated_at: '2023-07-20T13:02:15.612990',
			variants_meta: null,
			order_history: {
				'81569f54-aa20-42df-b0c8-5e3c21505480': {
					id: '81569f54-aa20-42df-b0c8-5e3c21505480',
					name: 'value',
					value: '2400',
					type: 'select',
					composite: {},
				},
				'b2be24ab-09a6-4406-98b0-e5b4ec1475b3': {
					id: 'b2be24ab-09a6-4406-98b0-e5b4ec1475b3',
					name: 'Quantity',
					value: '20',
					type: 'boolean',
					composite: {},
				},
			},
			custom_attributes: {
				'f7a4b289-73dd-4568-9d0f-3c90f985d7b1': {
					id: 'f7a4b289-73dd-4568-9d0f-3c90f985d7b1',
					name: 'Description',
					value:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					type: 'textarea',
					composite: {},
				},
				'81569f54-aa20-42df-b0c8-5e3c21505480': {
					id: '81569f54-aa20-42df-b0c8-5e3c21505480',
					name: 'Color',
					value: 'Red',
					type: 'select',
					composite: {},
				},
				'b2be24ab-09a6-4406-98b0-e5b4ec1475b3': {
					id: 'b2be24ab-09a6-4406-98b0-e5b4ec1475b3',
					name: 'Customizable',
					value: 'true',
					type: 'boolean',
					composite: {},
				},
			},
			tenant_id: '0f349df7-7c9b-4b95-b370-e889b63032f2',
			status: 'published',
			pricing: {
				created_at: '2023-07-20T13:49:43.800131',
				id: '6d2a188a-cea5-4826-a5bd-1da6174c6a06',
				updated_at: '2023-07-20T13:49:43.800131',
				product_id: '535e68d0-cdb4-406b-b7b3-ceae3633023c',
				pricing_rule: {
					name: 'Volume Discount',
					type: 'Volume',
					tiers: [
						{
							min_qty: 1,
							max_qty: 30.0,
							type: '',
							value: '',
							final_price: 300,
						},
						{
							min_qty: 31,
							max_qty: 100.0,
							type: '',
							value: '',
							final_price: 280,
						},
						{
							min_qty: 101,
							max_qty: 150.0,
							type: '',
							value: '',
							final_price: 250,
						},
						{
							min_qty: 151,
							max_qty: '',
							type: '',
							value: '',
							final_price: 220,
						},
					],
				},
				price_level_id: '40b84efa-88f8-47a2-b879-f760c0afe496',
				unit_id: 'bf9efd7f-ffb3-495d-8ada-8e78a5e2542d',
				tenant_id: '0f349df7-7c9b-4b95-b370-e889b63032f2',
				status: 'published',
				price: 780.0,
			},
			inventory: {
				meta: {},
				stock: 200.0,
				created_at: '2023-07-24T13:55:05.857426',
				id: '01a18587-d118-4ea9-9807-05086aaa7348',
				updated_at: '2023-07-24T13:55:05.857426',
				product_id: '535e68d0-cdb4-406b-b7b3-ceae3633023c',
				in_stock: true,
				tenant_id: '0f349df7-7c9b-4b95-b370-e889b63032f2',
				status: 'published',
			},
			category: [
				{
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					id: '21032b69-0330-4f63-ad6c-5c2a4eb828ec',
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					level: 2,
					name: 'Coverlet',
					children: null,
				},
				{
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					id: '21032b69-0330-4f63-ad6c-5c2a4eb828ec',
					children: null,
					level: 2,
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					name: 'Coverlet',
				},
				{
					children: null,
					id: '21032b69-0330-4f63-ad6c-5c2a4eb828ec',
					name: 'Coverlet',
					level: 2,
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
				},
				{
					id: '21032b69-0330-4f63-ad6c-5c2a4eb828ec',
					children: null,
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					level: 2,
					name: 'Coverlet',
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
				},
				{
					name: 'Coverlet',
					id: '21032b69-0330-4f63-ad6c-5c2a4eb828ec',
					children: null,
					parent_id: '861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					path: '861bc8a5-f6f9-43f4-92a5-f39a5db879db,861bc8a5-f6f9-43f4-92a5-f39a5db879db',
					level: 2,
				},
			],
			collections: null,
		},
	],
	paginator: {},
};
