import Breadcrumbs, { BreadcrumbsProps as MuiBreadcrumbsProps } from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import Grid from '../Grid';

type BreadcrumbsBaseProps = Pick<MuiBreadcrumbsProps, 'maxItems'>;

export interface BreadcrumbsProps extends BreadcrumbsBaseProps {
	links: LinksProps[];
	maxItems?: number;
	separatorIcon?: 'chevron' | 'slash';
}

interface LinksProps {
	id: number;
	linkTitle: string;
	link: any;
}

const Breadcrumb = ({ links, separatorIcon, ...rest }: BreadcrumbsProps) => (
	<Breadcrumbs
		separator={separatorIcon === 'chevron' ? <Icon iconName='IconChevronRight' /> : '/'}
		aria-label='breadcrumb'
		{...rest}
		sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
		{links.map((item: LinksProps, index: number) => {
			if (index + 1 !== links.length) {
				return (
					<Grid>
						<Link color='rgba(0, 0, 0, 0.6)' fontSize={'1.2rem'} underline='none' key={item.id} href={item.link}>
							{item.linkTitle !== 'undefined' ? item.linkTitle ?? '' : ''}
						</Link>
					</Grid>
				);
			}
			return (
				<Grid sx={{ maxWidth: '25rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
					<Typography color='rgba(0, 0, 0, 0.87)' key={item.id} sx={{ fontSize: '1.2rem' }}>
						{item.linkTitle !== 'undefined' ? item.linkTitle ?? '' : ''}
					</Typography>
				</Grid>
			);
		})}
	</Breadcrumbs>
);

Breadcrumb.defaultProps = {
	maxItems: 3,
	separatorIcon: 'slash',
};

export default Breadcrumb;
