const ERROR_STATE = 'error';
const ERROR_TITLE = 'Error';

const SUCCESS_STATE = 'success';
const SUCCESS_TITLE = 'Success';
const WARNING_STATE = 'warning';
const WARNING_TITLE = 'Warning';
const VERTICAL_TOP = 'top';
const HORIZONTAL_CENTER = 'center';
const REASON_CLICK = 'clickaway';
const LOCAL_HOST = 'localhost';
const VERCEL_APP = 'vercel.app';
const SUPPORT_EMAIL = 'mailto:help@wizcommerce.com';
const DEFAULT_ORDER_QUANTITY = 100;
const MIN_ORDER_QUANTITY = 10;
const STEP_INCREMENT = 5;
const DEMO = 'https://wizcommerce.com/book-a-demo/';

const types = {
	ERROR_STATE,
	ERROR_TITLE,
	SUCCESS_STATE,
	SUCCESS_TITLE,
	WARNING_STATE,
	WARNING_TITLE,
	VERTICAL_TOP,
	HORIZONTAL_CENTER,
	REASON_CLICK,
	LOCAL_HOST,
	VERCEL_APP,
	SUPPORT_EMAIL,
	DEMO,
	DEFAULT_ORDER_QUANTITY,
	MIN_ORDER_QUANTITY,
	STEP_INCREMENT,
};

export default types;
