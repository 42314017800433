import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { useState } from 'react';

import { secondary } from '../../../../utils/light.theme';
import Icon from '../Icon/Icon';

type SelectBaseProps = Omit<MuiSelectProps, 'IconComponent' | 'minRows' | 'maxRows' | 'rows'>;

export interface SelectProps extends SelectBaseProps {
	// label: string;
	// error?: boolean;
	// value?: string;
	helperText?: string;
	options: OptionProps[];
	// displayEmpty?: boolean;
	handleChange: (value: any) => any;
	name?: any;
}

export interface OptionProps {
	label: string;
	value: any;
}

const SingleSelect = ({
	options,
	label,
	value,
	error,
	helperText,
	displayEmpty,
	size,
	name,
	handleChange,
	defaultValue,
	...rest
}: SelectProps) => {
	const [option, setOption] = useState();

	const handleSelectChange = (event: any) => {
		setOption(event.target.value);
		handleChange(event.target);
	};

	const getIcon = () => {
		if (error) {
			return <ErrorOutlineIcon htmlColor='#D74C10' sx={{ marginRight: '.5em' }} />;
		} else {
			return <Icon iconName='IconChevronDown' color={secondary[600]} sx={{ marginRight: '.5em' }} />;
		}
	};

	return (
		<FormControl fullWidth error={error} size={size}>
			<InputLabel id='simple-select-label'>{label}</InputLabel>
			<Select
				labelId='simple-select-label'
				id='select-id'
				label={label}
				value={option}
				defaultValue={defaultValue ? defaultValue : options[0]?.value}
				displayEmpty={displayEmpty}
				onChange={handleSelectChange}
				IconComponent={getIcon}
				name={name}
				{...rest}>
				{options?.map(
					(item: any): JSX.Element => (
						<MenuItem value={item.value}>{item.label}</MenuItem>
					),
				)}
			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

SingleSelect.defaultProps = {
	disabled: false,
	error: false,
	value: '',
	helperText: '',
	displayEmpty: false,
	name: 'select',
};
export default SingleSelect;
